.advance-customer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
  display: grid;
  place-items: center;
}
.advance-customer-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: -1;
}
.advance-customer .content {
  padding: 25px;
  background: #fff;
  width: 494px;
  border-radius: 10px;
}
.advance-customer .content h4 {
  font-family: Asap;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #15294d;
}
.advance-customer .content input {
  width: 100%;
  height: 40px;
  margin-bottom: 20px;
  border: 1px solid #adcae2;
  outline: none;
  border-radius: 10px;
}
.advance-customer .content select {
  width: 100%;
  height: 40px;
  margin-bottom: 20px;
  border: 1px solid #adcae2;
  outline: none;
  border-radius: 10px;
}
.advance-customer .content textarea {
  width: 100%;
  height: 100px;
  margin-bottom: 20px;
  border: 1px solid #adcae2;
  outline: none;
  border-radius: 10px;
  resize: none;
}
.advance-customer .content span {
  margin-left: 5px;
  color: #61606199;
}
.advance-customer .content button {
  padding: 7px 28px;
  color: white;
  background: #4c9de8;
  border-radius: 5px;
  font-size: 14px;
  border: none;
  /* margin-left: 78%; */
  float: right;
}
@media only screen and (max-width: 768px) {
  .advance-customer .content {
    width: 85vw;
  }
  .advance-customer .content button {
    /* margin-left: ; */
  }
}
