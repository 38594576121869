.Refine-selection-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 5;
}

.Refine-selection-modal {
  position: absolute;
  top: 5%;
  left: 50%;
  height: 90vh;
  width: 80%;
  transform: translateX(-50%);
  background: #fff;
  border-radius: 10px;
  padding: 30px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.Refine-selection-modal::-webkit-scrollbar {
  width: 10px;
}

.Refine-selection-modal::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 20px;
}

.Refine-selection-modal .close-icon-wrapper {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.Refine-selection-modal .close-icon {
  width: 15px;
}

.Refine-selection-modal .form-container {
  width: 100%;
  font-family: "Asap", sans-serif;
  margin-top: 20px;
}
.Refine-selection-modal .form-header {
  font-weight: 800;
  font-size: 23px;
  color: #15294d;
  text-align: start;
}
.form-row-radio-section {
  margin-bottom: 1rem;
}
.form-row-radio-section .formfeedback {
  margin: 0;
}
.form-row-radio-wrapper {
  display: flex;
}
.form-row-radio {
  margin-right: 50px;
}
.Refine-selection-modal .form-container label,
.Refine-selection-modal .form-container legend {
  font-weight: 600;
  color: #616061;
  font-size: 16px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Refine-selection-modal .form-container .form-input-tile {
  height: 45px;
  width: 100%;
  background: #fff;
  border: 1px solid #adcae2;
  border-radius: 5px;
  outline: none;
  padding-left: 10px;
}
.Refine-selection-modal .form-container .form-input-error {
  height: 45px;
  width: 100%;
  background: #fff;
  border: 1px solid #dc3545;
  border-radius: 5px;
  outline: none;
  padding-left: 10px;
}
.formfeedback {
  color: #dc3545;
  font-size: 14px;
}
.Refine-selection-modal .form-container .form-input-radio {
  margin: 0px 5px 0px 0px;
  height: 20px;
  width: 20px;
}
.form-submit-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
  width: 100%;
}
.form-submit-btn button {
  padding: 10px 50px;
  margin-left: 20px;
  background: #4c9de8;
  border: 2px solid #4c9de8;
  border-radius: 5px;
  color: #fff;
  font-weight: 500;
}
.form-submit-btn button:hover {
  background-color: #0d1071;
  border: 2px solid #0d1071;
}
.form-submit-btn button:active {
  background-color: #0d1071;
  border: 2px solid #0d1071;
}

.refine-header-description {
  margin-top: 30px;
  margin-bottom: 20px;
  font-weight: 800;
  font-size: 23px;
  color: #15294d;
}

.refine-details-header {
  background: #adcae2;
  border-radius: 5px 5px 0px 0px;
  padding: 5px 15px;
  font-family: "Asap", sans-serif;
}

.refine-details-header.has-tooltip {
  display: flex;
  align-items: center;
}

.refine-details-header-text {
  margin: 0;
  color: #0d1071;
  font-weight: 600;
  font-size: 18px;
}
.refine-details-header-subtext {
  font-weight: 500;
  font-size: 14px;
  color: #616061;
  margin: 0;
}
.refine-details-body {
  border-radius: 0px 0px 5px 5px;
  border: 1px solid #adcae2;
  margin-bottom: 30px;
  padding: 15px 15px;
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.appliance-wrapper {
  display: flex;
  width: 45%;
  justify-content: space-between;
  margin-bottom: 15px;
}
.grid-appliance-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 15px;
}
.appliance-checkbox-name-label {
  width: 170px;
}
.appliance-wrapper-label {
  display: flex;
  width: 45%;
  justify-content: space-between;
}
.appliance-wrapper-label p {
  margin: 0;
}
.appliance-quantity-label,
.appliance-wattage-label,
.appliance-usage-label {
  width: 70px;
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  margin: 0;
  color: #616061;
}
.appliance-checkbox-name {
  display: flex;
  align-items: center;
  color: #4c9de8;
  font-family: "Asap", sans-serif;
  font-weight: 500;
  width: 180px;
}
.appliance-checkbox-name input {
  margin-right: 7px;
  height: 20px;
  width: 20px;
}
.appliance-quantity {
  display: flex;
  width: 70px;
  padding: 0px 2px;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #adcae2;
  border-radius: 5px;
}
.appliance-quantity img {
  cursor: pointer;
  height: 20px;
}
.appliance-quantity p {
  margin: 0;
  color: #15294d;
  font-family: "Asap", sans-serif;
  font-weight: 600;
}
.appliance-wattage-input-wrapper,
.appliance-usage-input-wrapper {
  border: 1px solid #adcae2;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 0px 3px;
  width: 70px;
}
.appliance-wattage-input,
.appliance-usage-input {
  height: 25px;
  width: 48px;
  outline: none;
  border: none;
}
.appliance-wattage-input-wrapper p {
  margin: 0;
  color: #444444;
  font-family: "Asap", sans-serif;
}
.refine-selection-details-element {
  width: 230px;
  margin-right: 20px;
}

@media (max-width: 80rem) {
  .appliance-wrapper {
    width: 100%;
  }
  .appliance-wrapper-label {
    width: 100%;
  }
  .appliance-label2 {
    display: none;
  }
}
@media (max-width: 60rem) {
  .Refine-selection-modal .form-container label,
  .Refine-selection-modal .form-container legend {
    font-size: 14px;
  }
}

@media (max-width: 40rem) {
  .Refine-selection-modal {
    width: 95%;
    padding: 10px;
  }
  .Refine-selection-modal .form-container .form-input-tile,
  .Refine-selection-modal .form-container .form-input-error {
    background: #fff;
  }
  .form-submit-btn button {
    padding: 8px 20px;
    background: #4c9de8;
    border: 2px solid #4c9de8;
    border-radius: 5px;
    color: #fff;
    font-weight: 500;
    font-size: 15px;
  }
  .appliance-quantity-label,
  .appliance-wattage-label,
  .appliance-usage-label {
    font-size: 11px;
  }
}

@media (max-width: 26rem) {
  .form-row-radio {
    margin-right: 30px;
  }
  .form-submit-btn button {
    padding: 6px 17px;
    background: #4c9de8;
    border: 2px solid #4c9de8;
    border-radius: 5px;
    color: #fff;
    font-weight: 500;
    font-size: 13px;
  }
  .Refine-selection-modal::-webkit-scrollbar {
    width: 6px;
  }
  .appliance-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  .appliance-checkbox-name {
    font-size: 10px;
    width: 120px;
  }
  .appliance-checkbox-name input {
    margin-right: 5px;
    height: 12px;
    width: 12px;
  }
  .appliance-quantity {
    width: 50px;
    height: 22px;
  }
  .appliance-quantity img {
    height: 15px;
  }
  .appliance-quantity p {
    font-size: 10px;
  }
  .appliance-wattage-input-wrapper,
  .appliance-usage-input-wrapper {
    border: 1px solid #adcae2;
    border-radius: 5px;
    display: flex;
    padding: 0px 3px;
    width: 50px;
  }

  .appliance-wattage-input,
  .appliance-usage-input {
    height: 20px;
    width: 28px;
    font-size: 10px;
    outline: none;
    border: none;
  }

  .appliance-wattage-input-wrapper p {
    font-size: 13px;
  }
}
