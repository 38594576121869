.NewExisting {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.fp-new,
.fp-exist {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 250px;
  height: 70px;
  color: #4c9de8;
  background: #f7fafc;
  border-radius: 5px;
  border: 2px solid #4c9de8;
  font-family: "Asap", sans-serif;
  font-size: 17px;
  font-weight: 600;
  text-decoration: none;
  margin: 8px 5px;
}
.fp-new:hover,
.fp-exist:hover {
  cursor: pointer;
  background-color: #0d1071;
  border: 2px solid #0d1071;
  color: #ffffff;
}

.selected {
  color: #ffffff;
  background: #4c9de8;
}

@media (max-width: 40rem) {
  .fp-new,
  .fp-exist {
    width: 180px;
    height: 50px;
    font-size: 13px;
    background: #fff;
  }
  .selected {
    color: #ffffff;
    background: #4c9de8;
  }
}

@media (max-width: 26rem) {
  .fp-new,
  .fp-exist {
    width: 160px;
    height: 50px;
    font-size: 12px;
    font-weight: 600;
  }
}

@media (max-width: 20rem) {
  .fp-new,
  .fp-exist {
    width: 140px;
    height: 50px;
    font-size: 12px;
    font-weight: 600;
  }
}
