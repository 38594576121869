.AccountVerificationPage {
  padding-top: 50px;
}
.AccountVerification-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
}
.AccountVerificationPage .login-header {
  width: 80%;
}
.AccountVerificationPage .login-header-text,
.AccountVerification-message {
  margin: 60px 0px 20px 0px;
  font-family: "Asap", sans-serif;
  font-weight: 600;
  font-size: 17px;
}
.AccountVerificationPage .form-container {
  font-family: "Asap", sans-serif;
}
.AccountVerificationPage .form-container .form-input-wrapper {
  margin-bottom: 20px;
}
.AccountVerificationPage .form-container .form-input-tile {
  height: 45px;
  width: 100%;
  border: 1px solid #adcae2;
  border-radius: 5px;
  outline: none;
  padding-left: 10px;
  font-family: "Asap", sans-serif;
  font-weight: 500;
}
.AccountVerificationPage .form-container .form-input-error {
  height: 45px;
  width: 100%;
  border: 1px solid #dc3545;
  border-radius: 5px;
  outline: none;
  padding-left: 10px;
}
.formfeedback {
  color: #dc3545;
  font-size: 14px;
}
.AccountVerificationPage .form-container .form-submit-btn {
  display: block;
}
.AccountVerificationPage .form-container .form-submit-btn button {
  width: 100%;
}

@media (max-width: 40rem) {
  .AccountVerificationPage {
    padding-top: 20px;
  }
  .AccountVerification-container {
    width: 80%;
  }
  .AccountVerificationPage .form-container .form-submit-btn button {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .AccountVerificationPage .form-container .form-input-tile,
  .AccountVerificationPage .form-container .form-input-error {
    background: #fff;
  }
  .AccountVerificationPage .form-container .form-input-tile {
    border: 1px solid #adcae2;
  }
}
@media (max-width: 26rem) {
  .AccountVerification-container {
    width: 90%;
  }
  .AccountVerificationPage .login-header {
    width: 90%;
  }
}
