.SelectFinancing {
  padding-top: 90px;
}
.select-finance-header {
  width: 80%;
  font-family: "Asap", sans-serif;
}
.select-finance-header-text {
  font-weight: 800;
  font-size: 23px;
  color: #15294d;
}
.SelectFinancing form {
  width: 80%;
  font-family: "Asap", sans-serif;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}
.SelectFinancing form .select-finance-input-wrapper {
  width: 180px;
  margin-right: 30px;
}
.SelectFinancing form .select-finance-date-input-wrapper {
  width: 360px;
  margin-right: 30px;
  padding-top: 20px;
}
.SelectFinancing form .select-finance-input-label {
  font-weight: 600;
  color: #616061;
  font-size: 16px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}
.SelectFinancing form .select-finance-input {
  height: 45px;
  width: 100%;
  background: #f7fafc;
  border: 1px solid #adcae2;
  border-radius: 5px;
  outline: none;
  padding-left: 10px;
}
.SelectFinancing form .form-input-error {
  height: 45px;
  width: 100%;
  background: #f7fafc;
  border: 1px solid #dc3545;
  border-radius: 5px;
  outline: none;
  padding-left: 10px;
}
.formfeedback {
  color: #dc3545;
  font-size: 14px;
}
.loan-payment-wrapper {
  width: 100%;
  margin-top: 30px;
}
.loan-info-plate-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}
.loan-info-plate {
  background: #fff;
  border-radius: 20px;
  height: 150px;
  width: 240px;
  padding: 35px 20px;
  margin: 0px 20px 20px 0px;
}
.loan-info-title {
  font-weight: 600;
  color: #616061;
  font-size: 17px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.loan-info {
  color: #15294d;
  font-weight: 600;
  font-size: 22px;
  margin: 0;
}
.finance-graph-wrapper {
  position: fixed;
  top: 90px;
  bottom: 0;
  right: 0;
  width: 50%;
  padding: 30px;
  background: #fff;
  overflow-y: scroll;
}

@media (max-width: 70rem) {
  .SelectFinancing form .select-finance-input-wrapper {
    width: 160px;
    margin-right: 20px;
  }
  .SelectFinancing form .select-finance-date-input-wrapper {
    width: 320px;
    margin-right: 20px;
  }
}
@media (max-width: 60rem) {
  .finance-graph-wrapper {
    position: relative;
    width: 100%;
    overflow-y: hidden;
  }
}
@media (max-width: 45rem) {
  .loan-info-plate {
    height: 120px;
    width: 210px;
    padding: 27px 20px;
    box-shadow: 0px 4px 8px rgba(21, 41, 77, 0.1);
  }
  .loan-info-title {
    font-size: 12px;
  }
  .loan-info {
    font-size: 20px;
  }
}
@media (max-width: 40rem) {
  .SelectFinancing {
    padding-top: 60px;
  }
  .SelectFinancing form .select-finance-input,
  .SelectFinancing form .form-input-error {
    background: #fff;
  }
  .loan-info-plate {
    height: 100px;
    width: 180px;
    padding: 20px 20px;
  }
  .loan-info-title {
    font-size: 12px;
    margin-bottom: 8px;
  }
  .loan-info {
    font-size: 17px;
  }
}
@media (max-width: 35rem) {
  .SelectFinancing form .select-finance-input-wrapper {
    width: 150px;
  }
  .SelectFinancing form .select-finance-date-input-wrapper {
    width: 300px;
  }
  .loan-info-plate {
    width: 150px;
  }
}
@media (max-width: 29rem) {
  .SelectFinancing form .select-finance-input-wrapper {
    width: 130px;
  }
  .SelectFinancing form .select-finance-date-input-wrapper {
    width: 260px;
  }
  .loan-info-plate {
    height: 120px;
    width: 120px;
    padding: 20px 14px;
  }
  .loan-info {
    font-size: 15px;
  }
}
@media (max-width: 27rem) {
  .SelectFinancing form .select-finance-input-wrapper {
    width: 120px;
  }
  .SelectFinancing form .select-finance-date-input-wrapper {
    width: 240px;
  }
}
@media (max-width: 26rem) {
  .SelectFinancing form {
    width: 90%;
  }
  .select-finance-header {
    width: 90%;
  }
  .SelectFinancing form .select-finance-input-label {
    font-size: 14px;
  }
}
@media (max-width: 20rem) {
  .SelectFinancing form .select-finance-input-wrapper {
    width: 110px;
  }
  .SelectFinancing form .select-finance-date-input-wrapper {
    width: 220px;
  }
}
