.root-loading-wrapper {
  position: fixed;
  height: 100vh;
  width: 100%;
  text-align: center;
  background-color: #fff;
  z-index: 5;
}

.root-loading-content {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.root-loading-content img {
  height: 150px;
}
