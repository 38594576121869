.CustomPreview {
  padding: 120px 60px 60px 60px;
  background: #f7fafc;
  min-height: 100vh;
}

.custom-preview-selection-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.selection-details-element {
  margin-right: 20px;
  width: 350px;
}

.selection-details-element-label {
  font-family: "Asap", sans-serif;
  font-weight: 600;
  color: #616061;
  font-size: 14px;
}

.selection-details-element-value {
  color: #15294d;
  font-family: "Asap", sans-serif;
  font-weight: 600;
  font-size: 18px;
}

@media (max-width: 40rem) {
  .CustomPreview {
    padding: 80px 20px 60px 20px;
    background: #fff;
  }
  .selection-details-element {
    display: flex;
    align-items: center;
  }
  .selection-details-element-label {
    font-family: "Asap", sans-serif;
    font-weight: 600;
    color: #616061;
    font-size: 12px;
    margin-right: 10px;
  }
  .selection-details-element-value {
    color: #15294d;
    font-family: "Asap", sans-serif;
    font-weight: 600;
    font-size: 15px;
  }
}
