.UserDetails {
  position: relative;
  width: 100%;
}
.userDetails-page-header div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-family: "Asap", sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #15294d;
}

.userDetails-page-header div p:first-child {
  font-size: 14px;
}

.userDetails-page-header > p {
  font-weight: bold;
}

.userDetails-page-header > p span {
  cursor: pointer;
  text-decoration: underline;
}
.credit-status-wrapper {
  display: flex;
  align-items: center;
  font-size: 18px;
}
.credit-status {
  height: 25px;
  width: 50px;
  border-radius: 5px;
  margin: 0px 0px 0px 10px;
}
.selected-user-details {
  position: absolute;
  display: flex;
  overflow-x: scroll;
  width: 100%;
  margin-bottom: 20px;
}
.selected-user-details::-webkit-scrollbar {
  height: 7px;
}
.selected-user-details::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 20px;
}
.user-detail-tile {
  background: #ffffff;
  border-radius: 10px;
  padding: 20px 30px;
  height: 350px;
  margin-right: 20px;
}
.user-details-description {
  font-family: "Asap", sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #15294d;
  margin-bottom: 20px;
}
.detail-description {
  font-family: "Asap", sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: #15294d;
  width: 300px;
}
.detail-value {
  color: #616061;
  margin-left: 7px;
}
.user-details-navigation {
  margin-top: 400px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-around;
}
.user-details-navigation-item-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.user-details-navigation-item {
  font-family: "Asap", sans-serif;
  font-weight: 600;
  font-size: 17px;
  margin: 0;
  color: #61606199;
}
.navigation-active {
  color: #15294d;
}
.user-details-navigation-indicator {
  height: 5px;
  width: 100px;
  background: #4c9de8;
  border-radius: 10px;
}
.UserDetails .preview-body {
  border-radius: 0px 0px 10px 10px;
  border: none;
  background: #fff;
}
.UserDetails .selection-details-element {
  margin-right: 20px;
  width: 250px;
}
.UserDetails .recommended-details-tile {
  margin-right: 10px;
  width: 310px;
}
.rank-feedback {
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 20px;
}
.user-details-payment-graph {
  background: #fff;
  padding: 30px;
  margin-bottom: 20px;
}
.user-details-payment-details {
  position: relative;
  width: 100%;
  height: 500px;
  overflow-x: scroll;
  margin-bottom: 50px;
}
.user-details-payment-details::-webkit-scrollbar {
  height: 7px;
}
.user-details-payment-details::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 20px;
}
.payment-details-table-head {
  background: #adcae2;
  color: #15294d;
  font-weight: 600;
  font-family: "Asap", sans-serif;
  padding: 20px 50px;
}
.payment-details-table th,
.payment-details-table td {
  padding: 10px 40px 0px 30px;
  font-size: 14px;
  width: 60px !important;
}
.payment-details-sn-head {
  border-top-left-radius: 15px;
}
.payment-details-status-head {
  border-top-right-radius: 15px;
}
.payment-details-table td {
  color: #15294d;
  font-weight: 600;
  font-family: "Asap", sans-serif;
  background: #fff;
}
.confirmpayment-btn {
  color: white;
  font-size: 12px;
  background: rgb(76, 157, 232);
  outline: none;
  border: none;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
}

.resend-activation-mail {
  border-top: 1px solid #cccccc;
  padding-top: 20px;
}

.resend-activation-mail button {
  color: white;
  background: rgb(76, 157, 232);
  outline: none;
  border: none;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
}
