.tooltip-wrapper {
  position: relative;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-left: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tooltip-wrapper .tooltipIcon {
  height: 15px;
  width: 15px;
}

.tooltip-wrapper .tooltip-content {
  position: absolute;
  left: -220px;
  top: 20px;
  width: 290px;
  background-color: white;
  border-radius: 8px;
  padding: 20px 10px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  font-size: 14px;
  color: #0d1071;
  font-weight: 600;
  display: none;
}

.tooltip-wrapper .envac-water-heater {
  left: -254px;
}

.tooltip-wrapper:hover .tooltip-content {
  display: block;
}

@media (max-width: 40rem) {
  .tooltip-content {
    width: 210px;
  }
}

@media (max-width: 30rem) {
  .tooltip-content {
    width: 210px;
  }
}
