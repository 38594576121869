.thankyou-wrapper {
  position: fixed;
  height: 100vh;
  width: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 5;
}

.thankyou-content {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -60%);
  background: #fff;
  border-radius: 10px;
  padding: 30px;
}

.thankyou-content h1 {
  margin-top: 30px;
  margin-bottom: 0px;
  font-family: "Oxygen", sans-serif;
  color: #15294d;
  font-size: 45px;
  font-weight: 700;
}

.thankyou-content p {
  font-size: 14px;
  margin: 0;
}

.thankyou-content div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 80px;
  width: 400px;
}

.thankyou-content a {
  font-size: 16px;
  margin-bottom: 15px;
}

.thankyou-content .login-link-wrapper {
  display: flex;
  justify-content: center;
}

@media (max-width: 40rem) {
  .thankyou-wrapper {
    background-color: #fff;
  }
  .thankyou-content h1 {
    margin-top: 30px;
    margin-bottom: 0px;
    font-family: "Oxygen", sans-serif;
    color: #15294d;
    font-size: 35px;
    font-weight: 700;
  }

  .thankyou-content p {
    font-size: 12px;
    margin: 0;
  }
  .thankyou-content div {
    flex-direction: column;
  }
}
