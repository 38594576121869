.RegisterPage {
  padding-top: 90px;
}

.RegisterPage {
  padding-top: 90px;
}
.RegisterPage .form-container {
  width: 80%;
  font-family: "Asap", sans-serif;
  margin-top: 40px;
}
.RegisterPage .form-container .form-subheader {
  color: #15294d;
  font-weight: 600;
  font-size: 16px;
  font-family: "Asap", sans-serif;
}
.RegisterPage .form-container label,
.RegisterPage .form-container legend {
  font-weight: 600;
  color: #616061;
  font-size: 16px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}
.RegisterPage .form-container .form-input-tile {
  height: 45px;
  width: 100%;
  background: #f7fafc;
  border: 1px solid #adcae2;
  border-radius: 5px;
  outline: none;
  padding: 6px 10px;
}
.RegisterPage .form-container .form-textarea-tile {
  height: 150px;
  width: 100%;
  background: #f7fafc;
  border: 1px solid #adcae2;
  border-radius: 5px;
  outline: none;
  padding: 6px 10px;
}
.RegisterPage .form-container .form-input-error {
  height: 45px;
  width: 100%;
  background: #f7fafc;
  border: 1px solid #dc3545;
  border-radius: 5px;
  outline: none;
  padding-left: 10px;
}
.RegisterPage .form-container .form-textarea-error {
  height: 150px;
  width: 100%;
  background: #f7fafc;
  border: 1px solid #dc3545;
  border-radius: 5px;
  outline: none;
  padding-left: 10px;
}
.formfeedback {
  color: #dc3545;
  font-size: 14px;
}
.RegisterPage .form-container .form-input-radio {
  margin: 0px 5px 0px 0px;
  height: 20px;
  width: 20px;
}

@media (max-width: 40rem) {
  .RegisterPage {
    padding-top: 60px;
  }
  .RegisterPage .form-container label,
  .RegisterPage .form-container legend {
    font-size: 14px;
  }
  .RegisterPage .form-container .form-input-tile,
  .RegisterPage .form-container .form-input-error {
    background: #fff;
  }
}

@media (max-width: 26rem) {
  .RegisterPage .form-container {
    width: 90%;
  }
  .form-row-radio {
    margin-right: 30px;
  }
}
