.agent-info-item .agent-info-item-label {
  font-weight: bold;
}

.agent-info-item .identification-wrapper {
  border-radius: 8px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  margin: 30px 0;
  max-width: 600px;
}

.agent-info-item .identification-wrapper-imgs {
  display: flex;
  flex-wrap: wrap;
}

.agent-info-item .identification-wrapper img {
  height: 100px;
  width: 200px;
  object-fit: contain;
  cursor: pointer;
}

.agent-fullimage-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.agent-fullimage-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 25px;
  background: #fff;
  height: 600px;
  width: 800px;
  border-radius: 8px;
  box-sizing: border-box;
}

.agent-fullimage-content p {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: #15294d;
  font-weight: bold;
  background: rgba(255, 255, 255, 0.5);
  padding: 3px 8px;
  border-radius: 5px;
}

.agent-fullimage {
  height: 100%;
  width: 100%;
  object-fit: contain;
  cursor: pointer;
}

@media (max-width: 60rem) {
  .agent-fullimage-content {
    height: 80%;
    width: 80%;
  }
}
