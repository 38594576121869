.StandardSolution {
  padding-top: 90px;
}
.standard-header {
  width: 80%;
  margin: 40px 0px 20px 0px;
  padding-left: 10px;
}
.standard-header-text {
  font-family: "Asap", sans-serif;
  font-weight: 800;
  color: #15294d;
  font-size: 23px;
}
.standard-plan-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.standard-plan {
  width: 280px;
  border-radius: 10px;
  margin-bottom: 40px;
  padding: 0 10px 20px 10px;
}
.standard-plan-starter-solar {
  background: #b8d5cd;
}
.standard-plan-starter-plus-solar {
  background: linear-gradient(
    180deg,
    #b8d5cd 0%,
    #ecfdec 44.29%,
    #b8d5cd 73.97%,
    #b8d5cd 100%
  );
}
.standard-plan-basic-solar {
  background: #adcae2;
}
.standard-plan-basic-plus-solar {
  background: linear-gradient(
    180deg,
    #adcae2 0%,
    #e3f7ff 44.29%,
    #adcae2 73.97%,
    #adcae2 100%
  );
}
.standard-plan-premium-solar {
  background: linear-gradient(
    180deg,
    #e2e2e4 0%,
    #eeeded 44.29%,
    #e2e2e4 73.97%,
    #e2e2e4 100%
  );
}
.standard-plan-silver-solar {
  background: linear-gradient(
    180deg,
    #c2c3c7 0%,
    #d8d8d8 44.29%,
    #c2c3c7 73.97%,
    #c2c3c7 100%
  );
}
.standard-plan-gold-solar {
  background: linear-gradient(
    180deg,
    #e0aa3e 0%,
    #e0aa3e 18%,
    #e0aa3e 30%,
    #f9f295 63.39%,
    #b88a44 96.98%
  );
}
.standard-plan-platinum-solar {
  background: linear-gradient(
    180deg,
    #999a9c 0%,
    #c2c3c7 33.49%,
    #d8d8d8 57.62%,
    #999a9c 100%
  );
}
.standard-plan-starter-backup {
  background: #d6e7de;
}
.standard-plan-starter-plus-backup {
  background: linear-gradient(
    180deg,
    #d6e7de 0%,
    #d6e7de 33.49%,
    #e7fff0 57.62%,
    #d6e7de 100%
  );
}
.standard-plan-basic-backup {
  background: #e1f0fc;
}
.standard-plan-basic-plus-backup {
  background: linear-gradient(
    180deg,
    #e1f0fc 0%,
    #e7fff0 33.49%,
    #e1f0fc 57.62%,
    #e1f0fc 100%
  );
}
.standard-plan-premium-backup {
  background: #f7dec5;
}
.standard-plan-content {
  text-align: center;
}
.standard-plan-img {
  margin-top: 20px;
  width: 90px;
  height: 90px;
}
.standard-plan-text {
  font-family: "Oxygen", sans-serif;
  font-weight: bold;
  font-size: 20px;
  color: #15294d;
  margin: 0;
}
.standard-plan:hover {
  cursor: pointer;
}

@media (max-width: 55rem) {
  .standard-plan {
    width: 350px;
  }
  .standard-header {
    width: 90%;
    margin: 60px 0px 20px 0px;
    padding-left: 10px;
  }
  .standard-header-text {
    font-size: 25px;
  }
  .standard-plan-text {
    font-size: 26px;
  }
}

@media (max-width: 50rem) {
  .standard-plan {
    width: 300px;
  }
  .standard-plan-text {
    font-size: 23px;
  }
}

@media (max-width: 40rem) {
  .StandardSolution {
    padding-top: 60px;
  }
  .standard-plan {
    width: 210px;
  }
  .standard-plan-text {
    font-size: 20px;
  }
}

@media (max-width: 30rem) {
  .standard-plan {
    width: 160px;
  }
  .standard-plan-img {
    margin-top: 20px;
    width: 50px;
    height: 50px;
  }
  .standard-plan-text {
    font-size: 18px;
  }
  .standard-header {
    margin: 30px 0px 20px 0px;
  }
}

@media (max-width: 26rem) {
  .standard-plan {
    width: 140px;
  }
}
