@import url("https://fonts.googleapis.com/css2?family=Asap:wght@400;600&display=swap");
.admin-dashboard-page {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
}
.admin-dashboard-sidebar {
  background: #0d1071;
  width: 294px;
  position: fixed;
  overflow-y: scroll;
  top: 0;
  bottom: 0;
}
.admin-dashboard-sidebar::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.admin-dashboard-sidebar::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 20px;
}
@media only screen and (max-width: 768px) {
  .admin-dashboard-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    transition: all 0.3s linear;
  }
  .translate {
    left: -294px;
  }
  .untranslate {
    left: 0;
  }
}
.admin-dashboard-sidebar-logo {
  width: 232px;
  margin-top: 14px;
  margin-left: 20px;
}
.admin-dashboard-sidebar-items {
  margin-top: 65.89px;
  list-style: none;
  color: #fff;
  font-size: 14px;
  display: grid;
  grid-template-columns: 1fr;
  place-items: center;
  padding-bottom: 270px;
}
.admin-dashboard-sidebar-items li {
  width: 251px;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  margin-bottom: 10px;
}
.admin-dashboard-sidebar-items span {
  width: 14px;
  height: 14px;
  object-fit: cover;
  display: inline-block;
  margin-left: 22px;
  margin-right: 22px;
}
.admin-dashboard-sidebar-items .active {
  border-radius: 10px;
  background: #4c9de8;
}
.admin-dashboard-settings-logout .active {
  border-radius: 10px;
  background: #4c9de8;
}
.admin-dashboard-page {
  display: flex;
}
.admin-dashboard-settings-logout {
  margin-top: 21px;
  list-style: none;
  color: #fff;
  font-size: 14px;
  display: grid;
  grid-template-columns: 1fr;
  place-items: center;
  padding-bottom: 100px;
}
.admin-dashboard-settings-logout li {
  width: 251px;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  margin-bottom: 10px;
}
.admin-dashboard-settings-logout span {
  width: 14px;
  height: 14px;
  object-fit: cover;
  display: inline-block;
  margin-left: 22px;
  margin-right: 22px;
}
.admin-dashboard-mainpage {
  width: calc(100% - 294px);
  margin-left: 294px;
}
@media only screen and (max-width: 768px) {
  .admin-dashboard-mainpage {
    grid-template-rows: 0px 0px 1fr;
    width: 100vw;
    grid-template-columns: 100vw;
    margin-left: 0;
    /* margin-top: 60px; */
  }
}
.admin-dashboard-mainpage .component-area {
  background: rgba(135, 206, 250, 0.1);
  margin: 0;
  box-sizing: border-box;
  padding: 30px 20px 66px 20px;
}
@media only screen and (max-width: 768px) {
  .admin-dashboard-mainpage .component-area {
    padding: 15px;
    margin-top: 45px;
    background: #fff;
  }
}
.admin-dashboard-mainpage .header-area {
  padding-top: 31px;
  padding-bottom: 31px;
  padding-left: 20px;
  padding-right: 80px;
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 768px) {
  .admin-dashboard-mainpage .header-area {
    display: none;
  }
}
.admin-dashboard-mainpage .header-area h4 {
  font-family: Oxygen;
  font-weight: bold;
  font-size: 18px;
  line-height: 38px;
  color: #15294d;
}
.admin-dashboard-mainpage .header-area input {
  width: 217px;
  height: 34px;
  font-size: 10px;
  padding-left: 35px;
}
