.customer-info {
  padding: 60px;
  padding-bottom: 174px;
  background: #fff;
}
.customer-info .flex {
  display: flex;
}
.customer-info .flex .profile-pic .div {
  position: relative;
  width: 100px;
  height: 100px;
}
.customer-info .flex .profile-pic .pic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.customer-info .flex .profile-pic .icon {
  position: absolute;
  bottom: 0;
  right: 0;
}
.customer-info .flex .profile-pic .icon .div2 {
  width: 26px;
  height: 26px;
  position: relative;
}
.customer-info .flex .profile-pic .icon .div2 .image2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.customer-info .flex .profile-pic .icon .div2 .image3 {
  position: absolute;
  z-index: 2;
  top: 5.5px;
  left: 3px;
}
.customer-info .flex div .name {
  font-family: Oxygen;
  font-weight: bold;
  font-size: 20px;
  line-height: 38px;
  color: #15294d;
}
.customer-info .flex div .email {
  font-family: Asap;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #616061;
}
.customer-info .customer-info-setion-header {
  font-family: Oxygen;
  font-weight: bold;
  font-size: 20px;
  line-height: 38px;
  color: #15294d;
  margin-top: 60px;
  margin-bottom: 20px;
  border-bottom: #c1c1c1 1px solid;
}
.customer-info .sub-heading {
  margin-bottom: 14px;
  font-family: Asap;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #15294d;
}
.customer-info .sub-heading .sub {
  color: #616061;
  font-weight: 500;
}

@media (max-width: 40rem) {
  .customer-info {
    padding: 20px;
  }
}
