.StandardPreview {
  padding: 130px 60px 60px 60px;
  background: #f7fafc;
  min-height: 100vh;
}
.preview-header-label {
  margin: 0px 0px 30px 0px;
  color: #15294d;
  font-size: 32px;
}
.preview-header {
  background: #adcae2;
  border-radius: 5px 5px 0px 0px;
  padding: 5px 15px;
}
.preview-header-text {
  margin: 0;
  color: #0d1071;
  font-weight: 600;
  font-family: "Asap", sans-serif;
  font-size: 20px;
}
.preview-body {
  border-radius: 0px 0px 5px 5px;
  border: 1px solid #adcae2;
  margin-bottom: 30px;
  padding: 15px 15px;
}
.preview-plan-text {
  font-weight: 600;
  color: #15294d;
  font-family: "Asap", sans-serif;
  font-size: 18px;
  margin: 0;
}
.preview-body-text {
  font-weight: 500;
  font-family: "Asap", sans-serif;
  margin: 0;
}

.preview-btn-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.recommended-details-body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.recommended-details-tile {
  background: #fff;
  border-radius: 10px;
  width: 350px;
  padding: 20px;
  margin-bottom: 20px;
}
.recommended-details-tile-header {
  display: flex;
  justify-content: space-between;
  padding-right: 40px;
}
.recommended-details-tile-header-text {
  font-family: "Asap", sans-serif;
  font-weight: 600;
  color: #15294d;
}
.recommended-details-tile-body {
  display: grid;
  grid-template-columns: auto auto;
}
.recommended-details-tile-body-text {
  font-size: 14px;
  color: #616061;
  font-weight: 500;
  font-family: "Asap", sans-serif;
}
.preview-refine-btn {
  padding: 17px 0px;
  border: 2px solid #4c9de8;
  border-radius: 5px;
  text-align: center;
  color: #4c9de8 !important;
  font-weight: 600;
  font-family: "Asap", sans-serif;
  font-size: 16px;
  background: transparent !important;
}
.preview-refine-btn:hover,
.preview-order-btn:hover {
  background: #0d1071 !important;
  border: 2px solid #0d1071;
  color: #fff !important;
}

@media (max-width: 40rem) {
  .StandardPreview {
    padding: 80px 20px 60px 20px;
    background: #fff;
  }
  .preview-header-label {
    margin: 0px 0px 30px 0px;
    color: #15294d;
    font-size: 20px;
  }
  .preview-header {
    border-radius: 10px 10px 0px 0px;
    padding: 8px 10px;
  }
  .preview-header-text {
    font-size: 15px;
  }
  .preview-body {
    border-radius: 0px 0px 10px 10px;
    padding: 10px 10px;
    margin-bottom: 20px;
  }
  .preview-plan-text {
    font-size: 16px;
  }
  .preview-body-text {
    font-size: 15px;
  }
  .recommended-details-tile {
    padding: 0px;
  }
  .preview-refine-btn {
    padding: 12px 0px;
    border: 1px solid #4c9de8;
    font-size: 14px;
    background: #fff !important;
  }
}
