.DesignSolutionPage {
  padding-top: 90px;
}
.DesignSolutionPage .form-container {
  width: 80%;
  font-family: "Asap", sans-serif;
  margin-top: 40px;
}
.form-header {
  font-weight: 800;
  font-size: 23px;
  color: #15294d;
}
.form-row-radio-section {
  margin-bottom: 1rem;
}
.form-row-radio-section .formfeedback {
  margin: 0;
}
.form-row-radio-wrapper {
  display: flex;
}
.form-row-radio {
  margin-right: 50px;
}
.form-subheader {
  font-size: 14px;
  color: #616061;
  font-weight: 500;
  margin-bottom: 40px;
}
.DesignSolutionPage .form-container label,
.DesignSolutionPage .form-container legend {
  font-weight: 600;
  color: #616061;
  font-size: 16px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}
.DesignSolutionPage .form-container .form-input-tile {
  height: 45px;
  width: 100%;
  background: #f7fafc;
  border: 1px solid #adcae2;
  border-radius: 5px;
  outline: none;
  padding-left: 10px;
}
.DesignSolutionPage .form-container .form-input-error {
  height: 45px;
  width: 100%;
  background: #f7fafc;
  border: 1px solid #dc3545;
  border-radius: 5px;
  outline: none;
  padding-left: 10px;
}
.formfeedback {
  color: #dc3545;
  font-size: 14px;
}
.DesignSolutionPage .form-container .form-input-radio {
  margin: 0px 5px 0px 0px;
  height: 20px;
  width: 20px;
}
.form-submit-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
  width: 100%;
}
.form-submit-btn button {
  padding: 10px 50px;
  background: #4c9de8;
  border: 2px solid #4c9de8;
  border-radius: 5px;
  color: #fff;
  font-weight: 500;
  margin: 0 10px;
}
.form-submit-btn button:hover {
  background-color: #0d1071;
  border: 2px solid #0d1071;
}
.form-submit-btn button:active {
  background-color: #0d1071;
  border: 2px solid #0d1071;
}
@media (max-width: 60rem) {
  .DesignSolutionPage .form-container label,
  .DesignSolutionPage .form-container legend {
    font-size: 14px;
  }
}

@media (max-width: 40rem) {
  .DesignSolutionPage {
    padding-top: 60px;
  }
  .DesignSolutionPage .form-container .form-input-tile,
  .DesignSolutionPage .form-container .form-input-error {
    background: #fff;
  }
  .form-submit-btn button {
    padding: 8px 20px;
    background: #4c9de8;
    border: 2px solid #4c9de8;
    border-radius: 5px;
    color: #fff;
    font-weight: 500;
    font-size: 15px;
  }
}

@media (max-width: 26rem) {
  .DesignSolutionPage .form-container {
    width: 90%;
  }
  .form-row-radio {
    margin-right: 30px;
  }
  .form-submit-btn button {
    padding: 6px 17px;
    background: #4c9de8;
    border: 2px solid #4c9de8;
    border-radius: 5px;
    color: #fff;
    font-weight: 500;
    font-size: 13px;
  }
}
