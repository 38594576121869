.billing table tr.headings {
  border-bottom: 1px solid #adcae2;
}
.billing table {
  width: 100%;
}
.billing table th {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 40px;
  font-family: Asap;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #616061;
}
.billing table td {
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 40px;
  font-family: Asap;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #15294d;
}
.billing table tr td.four {
  color: #fff;
  font-family: Asap;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  text-transform: uppercase;
  background: #fff;
  border-radius: 5px;
  display: grid;
  place-items: center;
}
.billing table tr td.seven {
  color: #fff;
  font-family: Asap;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  text-transform: capitalize;
  background: #fff;
  border-radius: 5px;
  display: grid;
  place-items: center;
}
.download-statement {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  color: #4c9de8;
  padding: 10px;
  border: 2px solid #4c9de8;
  border-radius: 10px;
}
@media only screen and (max-width: 768px) {
  .download-statement {
    position: absolute;
    top: -60px;
    right: 0px;
  }
}
.payment-history {
  padding: 21px 40px;
  background: #adcae2;
  border-radius: 10px 10px 0px 0px;
  position: relative;
}
@media only screen and (max-width: 768px) {
  .payment-history {
    padding: 10px 20px;
    margin-top: 40px;
  }
}
