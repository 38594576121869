.ComponentPricing {
  background: #fff;
  border-radius: 10px;
  padding: 30px 40px;
  margin: 0;
  box-sizing: border-box;
  box-sizing: border-box;
}
.productlist-text {
  margin: 0;
  color: #15294d;
  font-weight: 600;
  font-family: "Asap", sans-serif;
  font-size: 22px;
}
.productlist-table {
  margin-top: 30px;
}
.ComponentPricing th {
  padding: 5px 30px 5px 5px;
}
.productlist-table-head {
  background: #adcae2;
  color: #15294d;
  font-weight: 600;
  font-family: "Asap", sans-serif;
}
.productlist-description-head {
  border-radius: 5px 0px 0px 5px;
  padding: 6px 0px 6px 30px !important;
}
.productlist-totalprice-head {
  border-radius: 0px 5px 5px 0px;
  padding: 6px 50px 6px 0px !important;
}
.ComponentPricing td {
  border: 2px solid #adcae2;
  border-top: none;
  border-collapse: collapse;
  padding: 5px 30px 5px 5px;
  color: #15294d;
  font-weight: 600;
  font-family: "Asap", sans-serif;
}
.productlist-description {
  border-left: none !important;
  padding-left: 30px !important;
}
.productlist-totalprice {
  border-right: none !important;
  padding-right: 30px !important;
}

@media (max-width: 40rem) {
  .ComponentPricing {
    padding: 0px;
  }
}
