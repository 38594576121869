.CustomerDashboardComp .preview-body {
  border-radius: 0px 0px 10px 10px;
  border: none;
  background: #fff;
}
.CustomerDashboardComp .selection-details-element {
  margin-right: 20px;
  width: 250px;
}
.CustomerDashboardComp .recommended-details-tile {
  margin-right: 10px;
  width: 310px;
}
.reservation-fee-monthly-payment a {
  padding: 5px 10px;
  display: inline-block;
  background: #4c9de8;
  color: white;
  margin-bottom: 15px;
  text-decoration: none;
  border-radius: 5px;
}
.reservation-fee-monthly-payment a:hover {
  background: #fff;
  color: #4c9de8;
}
