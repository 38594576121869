.mobile-nav {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background: #0d1071;
  z-index: 5;
}
@media only screen and (max-width: 768px) {
  .mobile-nav {
    display: block;
  }
}
.mobile-nav .small-logo {
  display: inline-block;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
}
.mobile-nav .flex {
  display: flex;
  /* justify-content: space-between; */
}
.mobile-nav .fabars {
  display: inline-block;
  align-self: center;
  margin-left: calc(100vw - 160px);
  cursor: pointer;
}
