@import url(https://fonts.googleapis.com/css2?family=Asap:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Asap:wght@400;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.DesignSolutionPage {
  padding-top: 90px;
}
.DesignSolutionPage .form-container {
  width: 80%;
  font-family: "Asap", sans-serif;
  margin-top: 40px;
}
.form-header {
  font-weight: 800;
  font-size: 23px;
  color: #15294d;
}
.form-row-radio-section {
  margin-bottom: 1rem;
}
.form-row-radio-section .formfeedback {
  margin: 0;
}
.form-row-radio-wrapper {
  display: flex;
}
.form-row-radio {
  margin-right: 50px;
}
.form-subheader {
  font-size: 14px;
  color: #616061;
  font-weight: 500;
  margin-bottom: 40px;
}
.DesignSolutionPage .form-container label,
.DesignSolutionPage .form-container legend {
  font-weight: 600;
  color: #616061;
  font-size: 16px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}
.DesignSolutionPage .form-container .form-input-tile {
  height: 45px;
  width: 100%;
  background: #f7fafc;
  border: 1px solid #adcae2;
  border-radius: 5px;
  outline: none;
  padding-left: 10px;
}
.DesignSolutionPage .form-container .form-input-error {
  height: 45px;
  width: 100%;
  background: #f7fafc;
  border: 1px solid #dc3545;
  border-radius: 5px;
  outline: none;
  padding-left: 10px;
}
.formfeedback {
  color: #dc3545;
  font-size: 14px;
}
.DesignSolutionPage .form-container .form-input-radio {
  margin: 0px 5px 0px 0px;
  height: 20px;
  width: 20px;
}
.form-submit-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
  width: 100%;
}
.form-submit-btn button {
  padding: 10px 50px;
  background: #4c9de8;
  border: 2px solid #4c9de8;
  border-radius: 5px;
  color: #fff;
  font-weight: 500;
  margin: 0 10px;
}
.form-submit-btn button:hover {
  background-color: #0d1071;
  border: 2px solid #0d1071;
}
.form-submit-btn button:active {
  background-color: #0d1071;
  border: 2px solid #0d1071;
}
@media (max-width: 60rem) {
  .DesignSolutionPage .form-container label,
  .DesignSolutionPage .form-container legend {
    font-size: 14px;
  }
}

@media (max-width: 40rem) {
  .DesignSolutionPage {
    padding-top: 60px;
  }
  .DesignSolutionPage .form-container .form-input-tile,
  .DesignSolutionPage .form-container .form-input-error {
    background: #fff;
  }
  .form-submit-btn button {
    padding: 8px 20px;
    background: #4c9de8;
    border: 2px solid #4c9de8;
    border-radius: 5px;
    color: #fff;
    font-weight: 500;
    font-size: 15px;
  }
}

@media (max-width: 26rem) {
  .DesignSolutionPage .form-container {
    width: 90%;
  }
  .form-row-radio {
    margin-right: 30px;
  }
  .form-submit-btn button {
    padding: 6px 17px;
    background: #4c9de8;
    border: 2px solid #4c9de8;
    border-radius: 5px;
    color: #fff;
    font-weight: 500;
    font-size: 13px;
  }
}

.root-loading-wrapper {
  position: fixed;
  height: 100vh;
  width: 100%;
  text-align: center;
  background-color: #fff;
  z-index: 5;
}

.root-loading-content {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.root-loading-content img {
  height: 150px;
}

.loading-wrapper {
  position: fixed;
  height: 100vh;
  width: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 5;
}

.loading-content {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.loading-content img {
  height: 100px;
}

.FirstPage,
.introPage {
  padding-top: 90px;
}
.grid1 {
  width: 50%;
  min-height: 100vh;
  background: #f7fafc;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 10px;
}
.fp-solution {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  margin-top: 30px;
}
.fp-custom-solution,
.fp-standard-solution {
  width: 260px;
  height: 160px;
  box-shadow: 0px 4px 8px rgba(23, 43, 79, 0.2);
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px 2px 0px 2px;
}
.Design-Custom-Solution,
.Standard-Solution {
  text-align: center;
  font-family: "Asap", sans-serif;
  font-weight: 700;
  font-size: 18px;
  margin: 0 0 30px 0;
  color: #15294d;
}
.fp-select {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 40px;
  background: #4c9de8;
  border-radius: 5px;
  color: #ffffff;
  border: #4c9de8;
  font-family: "Asap", sans-serif;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
}
.fp-select:hover {
  color: #fff;
  background-color: #0d1071;
  border: 2px solid #0d1071;
}
.get-started-video {
  width: 100%;
  margin-top: 100px;
  text-align: center;
}
.get-started-video iframe {
  width: 90%;
}
.grid2 {
  position: fixed;
  top: 90px;
  bottom: 0;
  right: 0;
  width: 50%;
  background-color: rgba(21, 41, 77, 0.3);
  background-image: url("/static/media/Rectangle 53.e032ba76.png");
  background-blend-mode: overlay;
  background-size: cover;
  background-repeat: no-repeat;
}
.fp-reliable {
  margin: 120px 0 0 80px;
}
.fp-energy {
  font-family: "Oxygen", sans-serif;
  font-weight: bold;
  font-size: 55px;
  color: #ffffff;
}
.intro-get-started {
  position: absolute;
  bottom: 20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background: #4c9de8;
  border-radius: 5px;
  color: #ffffff;
  border: #4c9de8;
  font-family: "Asap", sans-serif;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  padding: 10px 30px;
  display: none;
}
.intro-get-started:hover {
  color: #fff;
  background-color: #0d1071;
  border: 2px solid #0d1071;
}

@media (max-width: 60rem) {
  .grid1 {
    width: 100%;
  }
  .grid2 {
    display: none;
  }
  .introPage .grid1 {
    display: none;
  }
  .introPage .grid2 {
    display: block;
    width: 100%;
  }
  .intro-get-started {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 40rem) {
  .FirstPage,
  .introPage {
    padding-top: 60px;
  }
  .grid1 {
    background: #fff;
    padding: 30px 10px;
  }
  .introPage .grid2 {
    top: 60px;
  }
  .fp-custom-solution,
  .fp-standard-solution {
    margin: 10px 2px 20px 2px;
  }
  .fp-reliable {
    margin: 120px 0 0 30px;
    padding-right: 10px;
  }
  .fp-energy {
    font-size: 33px;
  }
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 90px;
  padding: 10px 70px 10px 0px;
  background-color: #0d1071;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
}
.logo {
  width: 300px;
  cursor: pointer;
}
.header-right-wrapper {
  display: flex;
  align-content: center;
}
.header-right-wrapper a {
  text-decoration: none;
}
.logout-btn {
  color: #fff;
  margin: 0;
  display: flex;
  align-items: center;
  margin-right: 50px;
  cursor: pointer;
}
.logout-btn:hover {
  color: #d0d0d0;
}
.header-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 45px;
  background: #4c9de8;
  border-radius: 5px;
  color: #ffffff;
  border: #4c9de8;
  font-family: "Asap", sans-serif;
  font-weight: 600;
  font-size: 17px;
}
.header-btn:hover {
  cursor: pointer;
  background-color: #4c5da8;
}

@media (max-width: 40rem) {
  .header {
    height: 60px;
    padding: 10px 20px 10px 0px;
  }
  .logo {
    width: 200px;
  }
  .header-btn {
    display: none;
  }
}

.NewExisting {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.fp-new,
.fp-exist {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 250px;
  height: 70px;
  color: #4c9de8;
  background: #f7fafc;
  border-radius: 5px;
  border: 2px solid #4c9de8;
  font-family: "Asap", sans-serif;
  font-size: 17px;
  font-weight: 600;
  text-decoration: none;
  margin: 8px 5px;
}
.fp-new:hover,
.fp-exist:hover {
  cursor: pointer;
  background-color: #0d1071;
  border: 2px solid #0d1071;
  color: #ffffff;
}

.selected {
  color: #ffffff;
  background: #4c9de8;
}

@media (max-width: 40rem) {
  .fp-new,
  .fp-exist {
    width: 180px;
    height: 50px;
    font-size: 13px;
    background: #fff;
  }
  .selected {
    color: #ffffff;
    background: #4c9de8;
  }
}

@media (max-width: 26rem) {
  .fp-new,
  .fp-exist {
    width: 160px;
    height: 50px;
    font-size: 12px;
    font-weight: 600;
  }
}

@media (max-width: 20rem) {
  .fp-new,
  .fp-exist {
    width: 140px;
    height: 50px;
    font-size: 12px;
    font-weight: 600;
  }
}

.tooltip-wrapper {
  position: relative;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  margin-left: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tooltip-wrapper .tooltipIcon {
  height: 15px;
  width: 15px;
}

.tooltip-wrapper .tooltip-content {
  position: absolute;
  left: -220px;
  top: 20px;
  width: 290px;
  background-color: white;
  border-radius: 8px;
  padding: 20px 10px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
  font-size: 14px;
  color: #0d1071;
  font-weight: 600;
  display: none;
}

.tooltip-wrapper .envac-water-heater {
  left: -254px;
}

.tooltip-wrapper:hover .tooltip-content {
  display: block;
}

@media (max-width: 40rem) {
  .tooltip-content {
    width: 210px;
  }
}

@media (max-width: 30rem) {
  .tooltip-content {
    width: 210px;
  }
}

.Refine-selection-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 5;
}

.Refine-selection-modal {
  position: absolute;
  top: 5%;
  left: 50%;
  height: 90vh;
  width: 80%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background: #fff;
  border-radius: 10px;
  padding: 30px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.Refine-selection-modal::-webkit-scrollbar {
  width: 10px;
}

.Refine-selection-modal::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 20px;
}

.Refine-selection-modal .close-icon-wrapper {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.Refine-selection-modal .close-icon {
  width: 15px;
}

.Refine-selection-modal .form-container {
  width: 100%;
  font-family: "Asap", sans-serif;
  margin-top: 20px;
}
.Refine-selection-modal .form-header {
  font-weight: 800;
  font-size: 23px;
  color: #15294d;
  text-align: start;
}
.form-row-radio-section {
  margin-bottom: 1rem;
}
.form-row-radio-section .formfeedback {
  margin: 0;
}
.form-row-radio-wrapper {
  display: flex;
}
.form-row-radio {
  margin-right: 50px;
}
.Refine-selection-modal .form-container label,
.Refine-selection-modal .form-container legend {
  font-weight: 600;
  color: #616061;
  font-size: 16px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Refine-selection-modal .form-container .form-input-tile {
  height: 45px;
  width: 100%;
  background: #fff;
  border: 1px solid #adcae2;
  border-radius: 5px;
  outline: none;
  padding-left: 10px;
}
.Refine-selection-modal .form-container .form-input-error {
  height: 45px;
  width: 100%;
  background: #fff;
  border: 1px solid #dc3545;
  border-radius: 5px;
  outline: none;
  padding-left: 10px;
}
.formfeedback {
  color: #dc3545;
  font-size: 14px;
}
.Refine-selection-modal .form-container .form-input-radio {
  margin: 0px 5px 0px 0px;
  height: 20px;
  width: 20px;
}
.form-submit-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
  width: 100%;
}
.form-submit-btn button {
  padding: 10px 50px;
  margin-left: 20px;
  background: #4c9de8;
  border: 2px solid #4c9de8;
  border-radius: 5px;
  color: #fff;
  font-weight: 500;
}
.form-submit-btn button:hover {
  background-color: #0d1071;
  border: 2px solid #0d1071;
}
.form-submit-btn button:active {
  background-color: #0d1071;
  border: 2px solid #0d1071;
}

.refine-header-description {
  margin-top: 30px;
  margin-bottom: 20px;
  font-weight: 800;
  font-size: 23px;
  color: #15294d;
}

.refine-details-header {
  background: #adcae2;
  border-radius: 5px 5px 0px 0px;
  padding: 5px 15px;
  font-family: "Asap", sans-serif;
}

.refine-details-header.has-tooltip {
  display: flex;
  align-items: center;
}

.refine-details-header-text {
  margin: 0;
  color: #0d1071;
  font-weight: 600;
  font-size: 18px;
}
.refine-details-header-subtext {
  font-weight: 500;
  font-size: 14px;
  color: #616061;
  margin: 0;
}
.refine-details-body {
  border-radius: 0px 0px 5px 5px;
  border: 1px solid #adcae2;
  margin-bottom: 30px;
  padding: 15px 15px;
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.appliance-wrapper {
  display: flex;
  width: 45%;
  justify-content: space-between;
  margin-bottom: 15px;
}
.grid-appliance-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 15px;
}
.appliance-checkbox-name-label {
  width: 170px;
}
.appliance-wrapper-label {
  display: flex;
  width: 45%;
  justify-content: space-between;
}
.appliance-wrapper-label p {
  margin: 0;
}
.appliance-quantity-label,
.appliance-wattage-label,
.appliance-usage-label {
  width: 70px;
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  margin: 0;
  color: #616061;
}
.appliance-checkbox-name {
  display: flex;
  align-items: center;
  color: #4c9de8;
  font-family: "Asap", sans-serif;
  font-weight: 500;
  width: 180px;
}
.appliance-checkbox-name input {
  margin-right: 7px;
  height: 20px;
  width: 20px;
}
.appliance-quantity {
  display: flex;
  width: 70px;
  padding: 0px 2px;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #adcae2;
  border-radius: 5px;
}
.appliance-quantity img {
  cursor: pointer;
  height: 20px;
}
.appliance-quantity p {
  margin: 0;
  color: #15294d;
  font-family: "Asap", sans-serif;
  font-weight: 600;
}
.appliance-wattage-input-wrapper,
.appliance-usage-input-wrapper {
  border: 1px solid #adcae2;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 0px 3px;
  width: 70px;
}
.appliance-wattage-input,
.appliance-usage-input {
  height: 25px;
  width: 48px;
  outline: none;
  border: none;
}
.appliance-wattage-input-wrapper p {
  margin: 0;
  color: #444444;
  font-family: "Asap", sans-serif;
}
.refine-selection-details-element {
  width: 230px;
  margin-right: 20px;
}

@media (max-width: 80rem) {
  .appliance-wrapper {
    width: 100%;
  }
  .appliance-wrapper-label {
    width: 100%;
  }
  .appliance-label2 {
    display: none;
  }
}
@media (max-width: 60rem) {
  .Refine-selection-modal .form-container label,
  .Refine-selection-modal .form-container legend {
    font-size: 14px;
  }
}

@media (max-width: 40rem) {
  .Refine-selection-modal {
    width: 95%;
    padding: 10px;
  }
  .Refine-selection-modal .form-container .form-input-tile,
  .Refine-selection-modal .form-container .form-input-error {
    background: #fff;
  }
  .form-submit-btn button {
    padding: 8px 20px;
    background: #4c9de8;
    border: 2px solid #4c9de8;
    border-radius: 5px;
    color: #fff;
    font-weight: 500;
    font-size: 15px;
  }
  .appliance-quantity-label,
  .appliance-wattage-label,
  .appliance-usage-label {
    font-size: 11px;
  }
}

@media (max-width: 26rem) {
  .form-row-radio {
    margin-right: 30px;
  }
  .form-submit-btn button {
    padding: 6px 17px;
    background: #4c9de8;
    border: 2px solid #4c9de8;
    border-radius: 5px;
    color: #fff;
    font-weight: 500;
    font-size: 13px;
  }
  .Refine-selection-modal::-webkit-scrollbar {
    width: 6px;
  }
  .appliance-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  .appliance-checkbox-name {
    font-size: 10px;
    width: 120px;
  }
  .appliance-checkbox-name input {
    margin-right: 5px;
    height: 12px;
    width: 12px;
  }
  .appliance-quantity {
    width: 50px;
    height: 22px;
  }
  .appliance-quantity img {
    height: 15px;
  }
  .appliance-quantity p {
    font-size: 10px;
  }
  .appliance-wattage-input-wrapper,
  .appliance-usage-input-wrapper {
    border: 1px solid #adcae2;
    border-radius: 5px;
    display: flex;
    padding: 0px 3px;
    width: 50px;
  }

  .appliance-wattage-input,
  .appliance-usage-input {
    height: 20px;
    width: 28px;
    font-size: 10px;
    outline: none;
    border: none;
  }

  .appliance-wattage-input-wrapper p {
    font-size: 13px;
  }
}

.StandardSolution {
  padding-top: 90px;
}
.standard-header {
  width: 80%;
  margin: 40px 0px 20px 0px;
  padding-left: 10px;
}
.standard-header-text {
  font-family: "Asap", sans-serif;
  font-weight: 800;
  color: #15294d;
  font-size: 23px;
}
.standard-plan-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.standard-plan {
  width: 280px;
  border-radius: 10px;
  margin-bottom: 40px;
  padding: 0 10px 20px 10px;
}
.standard-plan-starter-solar {
  background: #b8d5cd;
}
.standard-plan-starter-plus-solar {
  background: linear-gradient(
    180deg,
    #b8d5cd 0%,
    #ecfdec 44.29%,
    #b8d5cd 73.97%,
    #b8d5cd 100%
  );
}
.standard-plan-basic-solar {
  background: #adcae2;
}
.standard-plan-basic-plus-solar {
  background: linear-gradient(
    180deg,
    #adcae2 0%,
    #e3f7ff 44.29%,
    #adcae2 73.97%,
    #adcae2 100%
  );
}
.standard-plan-premium-solar {
  background: linear-gradient(
    180deg,
    #e2e2e4 0%,
    #eeeded 44.29%,
    #e2e2e4 73.97%,
    #e2e2e4 100%
  );
}
.standard-plan-silver-solar {
  background: linear-gradient(
    180deg,
    #c2c3c7 0%,
    #d8d8d8 44.29%,
    #c2c3c7 73.97%,
    #c2c3c7 100%
  );
}
.standard-plan-gold-solar {
  background: linear-gradient(
    180deg,
    #e0aa3e 0%,
    #e0aa3e 18%,
    #e0aa3e 30%,
    #f9f295 63.39%,
    #b88a44 96.98%
  );
}
.standard-plan-platinum-solar {
  background: linear-gradient(
    180deg,
    #999a9c 0%,
    #c2c3c7 33.49%,
    #d8d8d8 57.62%,
    #999a9c 100%
  );
}
.standard-plan-starter-backup {
  background: #d6e7de;
}
.standard-plan-starter-plus-backup {
  background: linear-gradient(
    180deg,
    #d6e7de 0%,
    #d6e7de 33.49%,
    #e7fff0 57.62%,
    #d6e7de 100%
  );
}
.standard-plan-basic-backup {
  background: #e1f0fc;
}
.standard-plan-basic-plus-backup {
  background: linear-gradient(
    180deg,
    #e1f0fc 0%,
    #e7fff0 33.49%,
    #e1f0fc 57.62%,
    #e1f0fc 100%
  );
}
.standard-plan-premium-backup {
  background: #f7dec5;
}
.standard-plan-content {
  text-align: center;
}
.standard-plan-img {
  margin-top: 20px;
  width: 90px;
  height: 90px;
}
.standard-plan-text {
  font-family: "Oxygen", sans-serif;
  font-weight: bold;
  font-size: 20px;
  color: #15294d;
  margin: 0;
}
.standard-plan:hover {
  cursor: pointer;
}

@media (max-width: 55rem) {
  .standard-plan {
    width: 350px;
  }
  .standard-header {
    width: 90%;
    margin: 60px 0px 20px 0px;
    padding-left: 10px;
  }
  .standard-header-text {
    font-size: 25px;
  }
  .standard-plan-text {
    font-size: 26px;
  }
}

@media (max-width: 50rem) {
  .standard-plan {
    width: 300px;
  }
  .standard-plan-text {
    font-size: 23px;
  }
}

@media (max-width: 40rem) {
  .StandardSolution {
    padding-top: 60px;
  }
  .standard-plan {
    width: 210px;
  }
  .standard-plan-text {
    font-size: 20px;
  }
}

@media (max-width: 30rem) {
  .standard-plan {
    width: 160px;
  }
  .standard-plan-img {
    margin-top: 20px;
    width: 50px;
    height: 50px;
  }
  .standard-plan-text {
    font-size: 18px;
  }
  .standard-header {
    margin: 30px 0px 20px 0px;
  }
}

@media (max-width: 26rem) {
  .standard-plan {
    width: 140px;
  }
}

.feedback-modal-wrapper{
  z-index: 1;
}

.feedback-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 70vh;
  width: 40%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 10px;
  padding: 30px;
  overflow-y: scroll;
  overflow-x: hidden;
}

@media (max-width: 70rem) {
  .feedback-modal {
    width: 50%;
    padding: 20px;
  }
}

@media (max-width: 60rem) {
  .feedback-modal {
    width: 60%;
    padding: 20px;
  }
}

@media (max-width: 50rem) {
  .feedback-modal {
    width: 70%;
    padding: 20px;
  }
}

@media (max-width: 40rem) {
  .feedback-modal {
    width: 95%;
    padding: 20px;
  }
}
.SelectFinancing {
  padding-top: 90px;
}
.select-finance-header {
  width: 80%;
  font-family: "Asap", sans-serif;
}
.select-finance-header-text {
  font-weight: 800;
  font-size: 23px;
  color: #15294d;
}
.SelectFinancing form {
  width: 80%;
  font-family: "Asap", sans-serif;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}
.SelectFinancing form .select-finance-input-wrapper {
  width: 180px;
  margin-right: 30px;
}
.SelectFinancing form .select-finance-date-input-wrapper {
  width: 360px;
  margin-right: 30px;
  padding-top: 20px;
}
.SelectFinancing form .select-finance-input-label {
  font-weight: 600;
  color: #616061;
  font-size: 16px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}
.SelectFinancing form .select-finance-input {
  height: 45px;
  width: 100%;
  background: #f7fafc;
  border: 1px solid #adcae2;
  border-radius: 5px;
  outline: none;
  padding-left: 10px;
}
.SelectFinancing form .form-input-error {
  height: 45px;
  width: 100%;
  background: #f7fafc;
  border: 1px solid #dc3545;
  border-radius: 5px;
  outline: none;
  padding-left: 10px;
}
.formfeedback {
  color: #dc3545;
  font-size: 14px;
}
.loan-payment-wrapper {
  width: 100%;
  margin-top: 30px;
}
.loan-info-plate-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}
.loan-info-plate {
  background: #fff;
  border-radius: 20px;
  height: 150px;
  width: 240px;
  padding: 35px 20px;
  margin: 0px 20px 20px 0px;
}
.loan-info-title {
  font-weight: 600;
  color: #616061;
  font-size: 17px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.loan-info {
  color: #15294d;
  font-weight: 600;
  font-size: 22px;
  margin: 0;
}
.finance-graph-wrapper {
  position: fixed;
  top: 90px;
  bottom: 0;
  right: 0;
  width: 50%;
  padding: 30px;
  background: #fff;
  overflow-y: scroll;
}

@media (max-width: 70rem) {
  .SelectFinancing form .select-finance-input-wrapper {
    width: 160px;
    margin-right: 20px;
  }
  .SelectFinancing form .select-finance-date-input-wrapper {
    width: 320px;
    margin-right: 20px;
  }
}
@media (max-width: 60rem) {
  .finance-graph-wrapper {
    position: relative;
    width: 100%;
    overflow-y: hidden;
  }
}
@media (max-width: 45rem) {
  .loan-info-plate {
    height: 120px;
    width: 210px;
    padding: 27px 20px;
    box-shadow: 0px 4px 8px rgba(21, 41, 77, 0.1);
  }
  .loan-info-title {
    font-size: 12px;
  }
  .loan-info {
    font-size: 20px;
  }
}
@media (max-width: 40rem) {
  .SelectFinancing {
    padding-top: 60px;
  }
  .SelectFinancing form .select-finance-input,
  .SelectFinancing form .form-input-error {
    background: #fff;
  }
  .loan-info-plate {
    height: 100px;
    width: 180px;
    padding: 20px 20px;
  }
  .loan-info-title {
    font-size: 12px;
    margin-bottom: 8px;
  }
  .loan-info {
    font-size: 17px;
  }
}
@media (max-width: 35rem) {
  .SelectFinancing form .select-finance-input-wrapper {
    width: 150px;
  }
  .SelectFinancing form .select-finance-date-input-wrapper {
    width: 300px;
  }
  .loan-info-plate {
    width: 150px;
  }
}
@media (max-width: 29rem) {
  .SelectFinancing form .select-finance-input-wrapper {
    width: 130px;
  }
  .SelectFinancing form .select-finance-date-input-wrapper {
    width: 260px;
  }
  .loan-info-plate {
    height: 120px;
    width: 120px;
    padding: 20px 14px;
  }
  .loan-info {
    font-size: 15px;
  }
}
@media (max-width: 27rem) {
  .SelectFinancing form .select-finance-input-wrapper {
    width: 120px;
  }
  .SelectFinancing form .select-finance-date-input-wrapper {
    width: 240px;
  }
}
@media (max-width: 26rem) {
  .SelectFinancing form {
    width: 90%;
  }
  .select-finance-header {
    width: 90%;
  }
  .SelectFinancing form .select-finance-input-label {
    font-size: 14px;
  }
}
@media (max-width: 20rem) {
  .SelectFinancing form .select-finance-input-wrapper {
    width: 110px;
  }
  .SelectFinancing form .select-finance-date-input-wrapper {
    width: 220px;
  }
}

.StandardPreview {
  padding: 130px 60px 60px 60px;
  background: #f7fafc;
  min-height: 100vh;
}
.preview-header-label {
  margin: 0px 0px 30px 0px;
  color: #15294d;
  font-size: 32px;
}
.preview-header {
  background: #adcae2;
  border-radius: 5px 5px 0px 0px;
  padding: 5px 15px;
}
.preview-header-text {
  margin: 0;
  color: #0d1071;
  font-weight: 600;
  font-family: "Asap", sans-serif;
  font-size: 20px;
}
.preview-body {
  border-radius: 0px 0px 5px 5px;
  border: 1px solid #adcae2;
  margin-bottom: 30px;
  padding: 15px 15px;
}
.preview-plan-text {
  font-weight: 600;
  color: #15294d;
  font-family: "Asap", sans-serif;
  font-size: 18px;
  margin: 0;
}
.preview-body-text {
  font-weight: 500;
  font-family: "Asap", sans-serif;
  margin: 0;
}

.preview-btn-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.recommended-details-body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.recommended-details-tile {
  background: #fff;
  border-radius: 10px;
  width: 350px;
  padding: 20px;
  margin-bottom: 20px;
}
.recommended-details-tile-header {
  display: flex;
  justify-content: space-between;
  padding-right: 40px;
}
.recommended-details-tile-header-text {
  font-family: "Asap", sans-serif;
  font-weight: 600;
  color: #15294d;
}
.recommended-details-tile-body {
  display: grid;
  grid-template-columns: auto auto;
}
.recommended-details-tile-body-text {
  font-size: 14px;
  color: #616061;
  font-weight: 500;
  font-family: "Asap", sans-serif;
}
.preview-refine-btn {
  padding: 17px 0px;
  border: 2px solid #4c9de8;
  border-radius: 5px;
  text-align: center;
  color: #4c9de8 !important;
  font-weight: 600;
  font-family: "Asap", sans-serif;
  font-size: 16px;
  background: transparent !important;
}
.preview-refine-btn:hover,
.preview-order-btn:hover {
  background: #0d1071 !important;
  border: 2px solid #0d1071;
  color: #fff !important;
}

@media (max-width: 40rem) {
  .StandardPreview {
    padding: 80px 20px 60px 20px;
    background: #fff;
  }
  .preview-header-label {
    margin: 0px 0px 30px 0px;
    color: #15294d;
    font-size: 20px;
  }
  .preview-header {
    border-radius: 10px 10px 0px 0px;
    padding: 8px 10px;
  }
  .preview-header-text {
    font-size: 15px;
  }
  .preview-body {
    border-radius: 0px 0px 10px 10px;
    padding: 10px 10px;
    margin-bottom: 20px;
  }
  .preview-plan-text {
    font-size: 16px;
  }
  .preview-body-text {
    font-size: 15px;
  }
  .recommended-details-tile {
    padding: 0px;
  }
  .preview-refine-btn {
    padding: 12px 0px;
    border: 1px solid #4c9de8;
    font-size: 14px;
    background: #fff !important;
  }
}

.CustomPreview {
  padding: 120px 60px 60px 60px;
  background: #f7fafc;
  min-height: 100vh;
}

.custom-preview-selection-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.selection-details-element {
  margin-right: 20px;
  width: 350px;
}

.selection-details-element-label {
  font-family: "Asap", sans-serif;
  font-weight: 600;
  color: #616061;
  font-size: 14px;
}

.selection-details-element-value {
  color: #15294d;
  font-family: "Asap", sans-serif;
  font-weight: 600;
  font-size: 18px;
}

@media (max-width: 40rem) {
  .CustomPreview {
    padding: 80px 20px 60px 20px;
    background: #fff;
  }
  .selection-details-element {
    display: flex;
    align-items: center;
  }
  .selection-details-element-label {
    font-family: "Asap", sans-serif;
    font-weight: 600;
    color: #616061;
    font-size: 12px;
    margin-right: 10px;
  }
  .selection-details-element-value {
    color: #15294d;
    font-family: "Asap", sans-serif;
    font-weight: 600;
    font-size: 15px;
  }
}

.thankyou-wrapper {
  position: fixed;
  height: 100vh;
  width: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 5;
}

.thankyou-content {
  position: absolute;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -60%);
          transform: translate(-50%, -60%);
  background: #fff;
  border-radius: 10px;
  padding: 30px;
}

.thankyou-content h1 {
  margin-top: 30px;
  margin-bottom: 0px;
  font-family: "Oxygen", sans-serif;
  color: #15294d;
  font-size: 45px;
  font-weight: 700;
}

.thankyou-content p {
  font-size: 14px;
  margin: 0;
}

.thankyou-content div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 80px;
  width: 400px;
}

.thankyou-content a {
  font-size: 16px;
  margin-bottom: 15px;
}

.thankyou-content .login-link-wrapper {
  display: flex;
  justify-content: center;
}

@media (max-width: 40rem) {
  .thankyou-wrapper {
    background-color: #fff;
  }
  .thankyou-content h1 {
    margin-top: 30px;
    margin-bottom: 0px;
    font-family: "Oxygen", sans-serif;
    color: #15294d;
    font-size: 35px;
    font-weight: 700;
  }

  .thankyou-content p {
    font-size: 12px;
    margin: 0;
  }
  .thankyou-content div {
    flex-direction: column;
  }
}

.RegisterPage {
  padding-top: 90px;
}

.RegisterPage {
  padding-top: 90px;
}
.RegisterPage .form-container {
  width: 80%;
  font-family: "Asap", sans-serif;
  margin-top: 40px;
}
.RegisterPage .form-container .form-subheader {
  color: #15294d;
  font-weight: 600;
  font-size: 16px;
  font-family: "Asap", sans-serif;
}
.RegisterPage .form-container label,
.RegisterPage .form-container legend {
  font-weight: 600;
  color: #616061;
  font-size: 16px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}
.RegisterPage .form-container .form-input-tile {
  height: 45px;
  width: 100%;
  background: #f7fafc;
  border: 1px solid #adcae2;
  border-radius: 5px;
  outline: none;
  padding: 6px 10px;
}
.RegisterPage .form-container .form-textarea-tile {
  height: 150px;
  width: 100%;
  background: #f7fafc;
  border: 1px solid #adcae2;
  border-radius: 5px;
  outline: none;
  padding: 6px 10px;
}
.RegisterPage .form-container .form-input-error {
  height: 45px;
  width: 100%;
  background: #f7fafc;
  border: 1px solid #dc3545;
  border-radius: 5px;
  outline: none;
  padding-left: 10px;
}
.RegisterPage .form-container .form-textarea-error {
  height: 150px;
  width: 100%;
  background: #f7fafc;
  border: 1px solid #dc3545;
  border-radius: 5px;
  outline: none;
  padding-left: 10px;
}
.formfeedback {
  color: #dc3545;
  font-size: 14px;
}
.RegisterPage .form-container .form-input-radio {
  margin: 0px 5px 0px 0px;
  height: 20px;
  width: 20px;
}

@media (max-width: 40rem) {
  .RegisterPage {
    padding-top: 60px;
  }
  .RegisterPage .form-container label,
  .RegisterPage .form-container legend {
    font-size: 14px;
  }
  .RegisterPage .form-container .form-input-tile,
  .RegisterPage .form-container .form-input-error {
    background: #fff;
  }
}

@media (max-width: 26rem) {
  .RegisterPage .form-container {
    width: 90%;
  }
  .form-row-radio {
    margin-right: 30px;
  }
}

.AccountVerificationPage {
  padding-top: 50px;
}
.AccountVerification-container {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 50%;
}
.AccountVerificationPage .login-header {
  width: 80%;
}
.AccountVerificationPage .login-header-text,
.AccountVerification-message {
  margin: 60px 0px 20px 0px;
  font-family: "Asap", sans-serif;
  font-weight: 600;
  font-size: 17px;
}
.AccountVerificationPage .form-container {
  font-family: "Asap", sans-serif;
}
.AccountVerificationPage .form-container .form-input-wrapper {
  margin-bottom: 20px;
}
.AccountVerificationPage .form-container .form-input-tile {
  height: 45px;
  width: 100%;
  border: 1px solid #adcae2;
  border-radius: 5px;
  outline: none;
  padding-left: 10px;
  font-family: "Asap", sans-serif;
  font-weight: 500;
}
.AccountVerificationPage .form-container .form-input-error {
  height: 45px;
  width: 100%;
  border: 1px solid #dc3545;
  border-radius: 5px;
  outline: none;
  padding-left: 10px;
}
.formfeedback {
  color: #dc3545;
  font-size: 14px;
}
.AccountVerificationPage .form-container .form-submit-btn {
  display: block;
}
.AccountVerificationPage .form-container .form-submit-btn button {
  width: 100%;
}

@media (max-width: 40rem) {
  .AccountVerificationPage {
    padding-top: 20px;
  }
  .AccountVerification-container {
    width: 80%;
  }
  .AccountVerificationPage .form-container .form-submit-btn button {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .AccountVerificationPage .form-container .form-input-tile,
  .AccountVerificationPage .form-container .form-input-error {
    background: #fff;
  }
  .AccountVerificationPage .form-container .form-input-tile {
    border: 1px solid #adcae2;
  }
}
@media (max-width: 26rem) {
  .AccountVerification-container {
    width: 90%;
  }
  .AccountVerificationPage .login-header {
    width: 90%;
  }
}

.LoginPage {
  padding-top: 90px;
}
.LoginPage .login-header {
  width: 80%;
}
.LoginPage .login-header-text {
  margin: 60px 0px 20px 0px;
  font-family: "Asap", sans-serif;
  font-weight: 600;
  font-size: 17px;
}
.LoginPage .form-container {
  width: 80%;
  font-family: "Asap", sans-serif;
}
.form-input-wrapper .container {
  height: 45px;
  width: 100%;
  position: relative;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.form-input-wrapper .container input {
  position: absolute;
}
.form-input-wrapper .container .eye {
  position: absolute;
  right: 0;
  cursor: pointer;
  font-size: 22px;
  padding: 5px;
}
.LoginPage .form-container .form-input-wrapper {
  margin-bottom: 20px;
}
.LoginPage .form-container .form-input-tile {
  height: 100%;
  width: 100%;
  background: #f7fafc;
  border: 2px solid #adcae2;
  border-radius: 5px;
  outline: none;
  padding-left: 10px;
  font-family: "Asap", sans-serif;
  font-weight: 500;
}
.LoginPage .form-container .form-input-error {
  height: 100%;
  width: 100%;
  background: #f7fafc;
  border: 1px solid #dc3545;
  border-radius: 5px;
  outline: none;
  padding-left: 10px;
}
.formfeedback {
  color: #dc3545;
  font-size: 14px;
}
.LoginPage .form-container .remember-forgot {
  display: flex;
  justify-content: space-between;
}
.LoginPage .form-container .remember-me {
  display: flex;
  align-items: center;
  color: #4c9de8;
}
.LoginPage .form-container .remember-me input {
  margin-right: 7px;
  background: #f7fafc;
  border: 1px solid #4c9de8;
  height: 20px;
  width: 20px;
}
.LoginPage .form-container .form-submit-btn {
  display: block;
}
.LoginPage .form-container .form-submit-btn button {
  width: 100%;
}
.form-gotologin {
  text-align: center;
  margin-top: 30px;
}

@media (max-width: 40rem) {
  .LoginPage {
    padding-top: 60px;
  }
  .LoginPage .form-container .form-submit-btn button {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .LoginPage .form-container .form-input-tile,
  .LoginPage .form-container .form-input-error {
    background: #fff;
  }
  .LoginPage .form-container .form-input-tile {
    border: 1px solid #adcae2;
  }
}
@media (max-width: 26rem) {
  .LoginPage .form-container {
    width: 90%;
  }
  .LoginPage .login-header {
    width: 90%;
  }
}

.LandingDashboard {
  padding: 120px 60px 60px 60px;
  background: #f7fafc;
  min-height: 100vh;
}

.preview-header {
  background: #adcae2;
  border-radius: 10px 10px 0px 0px;
  padding: 5px 15px;
}
.preview-header-text {
  margin: 0;
  color: #0d1071;
  font-weight: 600;
  font-family: "Asap", sans-serif;
}

.LandingDashboard .preview-body {
  border-radius: 0px 0px 10px 10px;
  border: none;
  background: #fff;
}

.LandingDashboard .preview-selection-details {
  margin-bottom: 30px;
}

.LandingDashboard .preview-dashboard .preview-body {
  border-radius: 10px;
  border: none;
  padding: 15px 50px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.landing-dashboard-user-personal-wrapper {
  display: flex;
  flex-direction: column;
}
.run-credit-check {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.run-credit-check a {
  margin-bottom: 25px;
}
.run-credit-check a,
.run-credit-check button {
  padding: 10px 20px;
  font-size: 18px;
  display: flex;
  align-items: center;
  background: #4c9de8;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  border: 2px solid #4c9de8;
}
.run-credit-check a:hover,
.run-credit-check button:hover {
  background: #fff;
  color: #4c9de8;
}
.landing-dashboard-user-personal {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
}

.landing-dashboard-user-img {
  position: relative;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background: #adcae2;
  margin-right: 30px;
  margin-bottom: 20px;
}

.landing-dashboard-user-img-icon {
  position: absolute;
  color: transparent;
  width: 30px;
  bottom: 0;
  right: 0;
}

.landing-dashboard-user-img-icon::-webkit-file-upload-button {
  visibility: hidden;
}
.landing-dashboard-user-img-icon::before {
  content: "S";
  display: inline-block;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAQHSURBVHgBrVZfbFNVGP/uv1XWdSsGKHYsrIskq5NQRafIA519IT4sbRQyHtT5xIMPmw++uJiyhy0mvgDGRIyJmz4MhKQLJvpUVx/G4lApUGAJCx1bWhgsrGu3lvb23sv3HdbR0t67DvglN/eee849v/P9+32Xg3Vw9uKcWwQ4oGmaWwNohscXIcEBhDUOworGDx9u3xE22oczIhA0zS8KvPvV7RbYajFBbY0AtSaRzct5FZYyMsQXM+zK5PIhheP6D73dFKqK6OdLUas1L/hfksTet1pehi1IUA1mF1bgeiwJ6Wz+eLJG6f/sDUdCl2hkItosCWJgl63O5bTXgyTysBHIigo34km4eTcZxmffkX2OmTIissQii2Ov2S0uZ2MDPA+I7HosEU5JakfBMrEwSe5qIUt0SJKpFRgJBNm9gHqLGT7wvAtNjdtK1pI30CLX9J2UH4dfrFlEga+ThDFPm62iu+Zi98Db/RW7Pw0iGR0aLCMjNwYj87CcyXYc2ucIsV05zC5nY+WYjF+M6JIUH+ICriuGJPCwF5NJEAQ/4/htMuqy1JguHdzzCltw6pfz8O33IyUu2ijIpUc/6YQvPz8Cv/8fg2xO7uB54LvtmzexBXSqr7/5iZEMf9dX5o797bvh5EAvc9XJwR54D8eVQN/TYS9MXgWqQZ7n3egrbk+BaBwnCvgzOFHyMZ0uMDQATTu2oTuvQoOlDgkH2Hs9kNupDjmOOyBiNrgaamvKFp0e/WvtucvrYRt6P+1Dq58c5vVWBwSGB1mcTo8GK5KZUU1Iuij6VknQVSKGg5532EbFJITIVBTOYMp3ed/X/XZVspqrKv3drS3o70jFucjULYylbb0tgIhmUJ8MF83F5qEN3VQJ9RgrowylekIkePTfzEpOASP8EfwHunweaLKXZiGNKY1/wJLQw1JaJlUI89hn/l5IPgQj/Pjrebh24xaMYuApMSitj37cycZLqWU4o5MIhPhiGlTQLougqqHp+WX/ekLq7e5jmUcX1ddcHDMNE+GUgTWMKPEQVFkdEkmHzk3Ohu6nsm6qaCNQEdJVLRazAJlsPnR4vyPMsk5TlP7/og/gw86Osjg8K7bbtoLD6QQF96bxWgGdm7x9fJetvmezkGOVryei1YA807b3TZhPqyc+at/ZW0IUwManyfxYq73hhTU+ARufb7XxrRUsveCw/U7Fl8JXZhOY/xpsFPTNldnFxyS4l6/ov6FMe8gyReaPmU1SD3XKnVvMVZEspLLwL8Z5JSufQEuO+Yx+TopxdiLq5rBpmU2i227dBKTwJL4FXSQ1SWOh38canL63DLm8GqKkoiyutJ+xmpKF41GXIvHd1E5I6fGVdXVqhlSFCp5qUY+ggEecjc6wiHEvXgAAAABJRU5ErkJggg==);
  background-size: cover;
  border: 1px solid #999;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-size: 5px;
}
.landing-dashboard-user-img-icon:hover::before {
  border-color: black;
}

.landing-dashboard-user-name {
  color: #15294d;
  font-family: "Oxygen", sans-serif;
  font-weight: 600;
  font-size: 30px;
}

.landing-dashboard-user-email {
  color: #616061;
  font-family: "Asap", sans-serif;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 0;
}

.landing-dashboard-contact-info {
  color: #15294d;
  font-family: "Oxygen", sans-serif;
  font-weight: 700;
}

.landing-dashboard-installation-address {
  margin: 20px 0px 10px 0px;
}

.landing-dashboard-installation-address,
.landing-dashboard-phone {
  color: #616061;
  font-family: "Asap", sans-serif;
  font-weight: 500;
}

.landing-dashboard-installation-address .bold,
.landing-dashboard-phone .bold {
  color: #15294d;
  font-weight: 700;
}

@media (max-width: 40rem) {
  .LandingDashboard {
    padding: 80px 20px 60px 20px;
    background: #fff;
  }
  .LandingDashboard .preview-body {
    border-radius: 0px 0px 10px 10px;
    border: 1px solid #adcae2;
  }
  .LandingDashboard .preview-dashboard .preview-body {
    padding: 5px 0px;
  }
  .run-credit-check {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  .run-credit-check a,
  .run-credit-check button {
    padding: 5px 10px;
    font-size: 16px;
    margin: 25px 0px;
  }
  .landing-dashboard-user-img {
    margin-right: 20px;
  }
  .landing-dashboard-user-name {
    font-size: 18px;
  }
  .landing-dashboard-user-email {
    font-size: 15px;
  }
}

@media (max-width: 23rem) {
  .run-credit-check a,
  .run-credit-check button {
    font-size: 14px;
  }
}

.customer-dashboard-page {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
}
.customer-dashboard-sidebar {
  background: #0d1071;
  width: 294px;
  position: fixed;
  overflow-y: scroll;
  top: 0;
  bottom: 0;
}
.customer-dashboard-sidebar::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.customer-dashboard-sidebar::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 20px;
}
@media only screen and (max-width: 768px) {
  .customer-dashboard-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    transition: all 0.3s linear;
  }
  .translate {
    left: -294px;
  }
  .untranslate {
    left: 0;
  }
}
.customer-dashboard-sidebar-logo {
  width: 230px;
  margin-top: 14px;
  margin-left: 20px;
}
.customer-dashboard-sidebar-items {
  margin-top: 65.89px;
  list-style: none;
  color: #fff;
  font-size: 14px;
  display: grid;
  grid-template-columns: 1fr;
  place-items: center;
  padding-bottom: 100px;
}
.customer-dashboard-sidebar-items li {
  width: 251px;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  margin-bottom: 10px;
}
.customer-dashboard-sidebar-items span {
  width: 14px;
  height: 14px;
  object-fit: cover;
  display: inline-block;
  margin-left: 22px;
  margin-right: 22px;
}
.customer-dashboard-sidebar-items .active {
  border-radius: 10px;
  background: #4c9de8;
}
.customer-dashboard-settings-logout .active {
  border-radius: 10px;
  background: #4c9de8;
}
.customer-dashboard-page {
  display: flex;
}
.customer-dashboard-settings-logout {
  margin-top: 21px;
  list-style: none;
  color: #fff;
  font-size: 14px;
  display: grid;
  grid-template-columns: 1fr;
  place-items: center;
  padding-bottom: 100px;
}
.customer-dashboard-settings-logout li {
  width: 251px;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  margin-bottom: 10px;
}
.customer-dashboard-settings-logout span {
  width: 14px;
  height: 14px;
  object-fit: cover;
  display: inline-block;
  margin-left: 22px;
  margin-right: 22px;
}
.customer-dashboard-mainpage {
  width: calc(100vw - 294px);
  margin-left: 294px;
}
@media only screen and (max-width: 768px) {
  .customer-dashboard-mainpage {
    grid-template-rows: 0px 0px 1fr;
    width: 100vw;
    grid-template-columns: 100vw;
    margin-left: 0px;
  }
}
.customer-dashboard-mainpage .profile {
  border-bottom: 1px solid #adcae2;
  text-align: right;
}
@media only screen and (max-width: 768px) {
  .customer-dashboard-mainpage .profile {
    margin-top: 50px;
    overflow: hidden;
  }
}
.customer-dashboard-mainpage .profile .name {
  font-weight: 600;
  font-size: 16px;
  color: #15294d;
  margin-left: 11.23px;
}
.customer-dashboard-mainpage .profile .dropdown {
  display: inline-block;
  margin-right: 30px;
  margin-left: 5px;
  color: #15294d;
  cursor: pointer;
}
.customer-dashboard-mainpage .profile .image {
  position: relative;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #4c9de8;
}

.customer-dashboard-mainpage .profile .bell {
  position: relative;
  margin-right: 20px;
}
.customer-dashboard-mainpage .profile .bell .active {
  position: absolute;
  top: 4px;
  right: 3px;
}
.customer-dashboard-mainpage .account-number-address {
  padding: 10px 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 15px;
  font-weight: 600;
  color: #15294d;
}
.customer-dashboard-mainpage .component-area {
  background: rgba(135, 206, 250, 0.1);
  margin: 0;
  box-sizing: border-box;
  padding: 30px 20px 66px 20px;
}

@media (max-width: 40rem) {
  .customer-dashboard-mainpage .component-area {
    margin-top: 0px;
    width: 100%;
    min-height: 100vh;
  }
  .customer-dashboard-mainpage .account-number-address {
    padding: 10px 20px;
  }
}

.CustomerDashboardComp .preview-body {
  border-radius: 0px 0px 10px 10px;
  border: none;
  background: #fff;
}
.CustomerDashboardComp .selection-details-element {
  margin-right: 20px;
  width: 250px;
}
.CustomerDashboardComp .recommended-details-tile {
  margin-right: 10px;
  width: 310px;
}
.reservation-fee-monthly-payment a {
  padding: 5px 10px;
  display: inline-block;
  background: #4c9de8;
  color: white;
  margin-bottom: 15px;
  text-decoration: none;
  border-radius: 5px;
}
.reservation-fee-monthly-payment a:hover {
  background: #fff;
  color: #4c9de8;
}

.customer-info {
  padding: 60px;
  padding-bottom: 174px;
  background: #fff;
}
.customer-info .flex {
  display: flex;
}
.customer-info .flex .profile-pic .div {
  position: relative;
  width: 100px;
  height: 100px;
}
.customer-info .flex .profile-pic .pic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.customer-info .flex .profile-pic .icon {
  position: absolute;
  bottom: 0;
  right: 0;
}
.customer-info .flex .profile-pic .icon .div2 {
  width: 26px;
  height: 26px;
  position: relative;
}
.customer-info .flex .profile-pic .icon .div2 .image2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.customer-info .flex .profile-pic .icon .div2 .image3 {
  position: absolute;
  z-index: 2;
  top: 5.5px;
  left: 3px;
}
.customer-info .flex div .name {
  font-family: Oxygen;
  font-weight: bold;
  font-size: 20px;
  line-height: 38px;
  color: #15294d;
}
.customer-info .flex div .email {
  font-family: Asap;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #616061;
}
.customer-info .customer-info-setion-header {
  font-family: Oxygen;
  font-weight: bold;
  font-size: 20px;
  line-height: 38px;
  color: #15294d;
  margin-top: 60px;
  margin-bottom: 20px;
  border-bottom: #c1c1c1 1px solid;
}
.customer-info .sub-heading {
  margin-bottom: 14px;
  font-family: Asap;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  color: #15294d;
}
.customer-info .sub-heading .sub {
  color: #616061;
  font-weight: 500;
}

@media (max-width: 40rem) {
  .customer-info {
    padding: 20px;
  }
}

.wallet-cards {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
}

.wallet-details {
  background-color: #0d1071;
  color: white;
  border-radius: 8px;
  padding: 30px 20px;
  margin-bottom: 20px;
  width: 400px;
  box-sizing: border-box;
}

.wallet-details p {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wallet-details button {
  border: none;
  outline: none;
  border-radius: 8px;
  background-color: white;
  color: #0d1071;
  height: 40px;
  padding: 0 30px;
  font-size: 14px;
  margin-top: 20px;
}

.wallet-details button img {
  width: 20px;
  margin-right: 10px;
}

.wallet-details .referrals-link-copied {
  color: white;
  margin-left: 60px;
}

.wallet-input-wrapper {
  background-color: white;
  border-radius: 8px;
  box-shadow: -1px 1px 15px #c7c6c6;
  padding: 30px 20px;
  width: 400px;
  margin-bottom: 20px;
  color: #0d1071;
}

.wallet-input-wrapper input {
  width: 100%;
  height: 40px;
  margin-bottom: 20px;
  border: 1px solid #adcae2;
  outline: none;
  border-radius: 10px;
  padding: 0 10px;
}

.wallet-input-wrapper button {
  border: none;
  outline: none;
  border-radius: 8px;
  background-color: #0d1071;
  color: white;
  height: 40px;
  padding: 0 30px;
  font-size: 14px;
  margin-top: 20px;
}

.wallet-input-wrapper .asset-name {
  margin: 0;
  font-weight: bold;
}

.wallet-input-wrapper .asset-unit-name {
  margin: 0;
  font-size: 14px;
}

.wallet-input-wrapper .asset-amount {
  margin: 0;
}

@media (max-width: 60rem) {
  .wallet-details {
    width: 100%;
  }

  .wallet-details p {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .wallet-input-wrapper {
    width: 100%;
  }
}

.billing table tr.headings {
  border-bottom: 1px solid #adcae2;
}
.billing table {
  width: 100%;
}
.billing table th {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 40px;
  font-family: Asap;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #616061;
}
.billing table td {
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 40px;
  font-family: Asap;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #15294d;
}
.billing table tr td.four {
  color: #fff;
  font-family: Asap;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  text-transform: uppercase;
  background: #fff;
  border-radius: 5px;
  display: grid;
  place-items: center;
}
.billing table tr td.seven {
  color: #fff;
  font-family: Asap;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  text-transform: capitalize;
  background: #fff;
  border-radius: 5px;
  display: grid;
  place-items: center;
}
.download-statement {
  display: inline-block;
  font-size: 14px;
  font-weight: 600;
  color: #4c9de8;
  padding: 10px;
  border: 2px solid #4c9de8;
  border-radius: 10px;
}
@media only screen and (max-width: 768px) {
  .download-statement {
    position: absolute;
    top: -60px;
    right: 0px;
  }
}
.payment-history {
  padding: 21px 40px;
  background: #adcae2;
  border-radius: 10px 10px 0px 0px;
  position: relative;
}
@media only screen and (max-width: 768px) {
  .payment-history {
    padding: 10px 20px;
    margin-top: 40px;
  }
}

.settings-form-input-wrapper{
    margin-top: 20px;
    width: 500px;
    position: relative;
}
.settings-form-input-label{
    margin: 0;
}
.settings-form-input-wrapper input{
    border-radius: 10px;
    width: 100%;
    height: 40px;
    outline: none;
    padding: 0 30px 0 10px;
}
.settings-form-input-tile{
    border: 1px solid #adcae2;
}
.settings-form-input-error{
    border: 1px solid #dc3545;
}
.settings-formfeedback{
    color: #dc3545;
}
.settings-form-input-eye{
    position: absolute;
    top: 30px;
    right: 10px;
    cursor: pointer;
}
.settings-form-submit-btn button{
    padding: 7px 40px;
    color: white;
    background: #4c9de8;
    border-radius: 5px;
    font-size: 14px;
    border: none;
    margin-top: 20px;
}

@media (max-width: 60rem) {
    .settings-form-input-wrapper{
        width: 100%;
    }
}
.mobile-nav {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background: #0d1071;
  z-index: 5;
}
@media only screen and (max-width: 768px) {
  .mobile-nav {
    display: block;
  }
}
.mobile-nav .small-logo {
  display: inline-block;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
}
.mobile-nav .flex {
  display: flex;
  /* justify-content: space-between; */
}
.mobile-nav .fabars {
  display: inline-block;
  align-self: center;
  margin-left: calc(100vw - 160px);
  cursor: pointer;
}

.admin-dashboard-page {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
}
.admin-dashboard-sidebar {
  background: #0d1071;
  width: 294px;
  position: fixed;
  overflow-y: scroll;
  top: 0;
  bottom: 0;
}
.admin-dashboard-sidebar::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.admin-dashboard-sidebar::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 20px;
}
@media only screen and (max-width: 768px) {
  .admin-dashboard-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    transition: all 0.3s linear;
  }
  .translate {
    left: -294px;
  }
  .untranslate {
    left: 0;
  }
}
.admin-dashboard-sidebar-logo {
  width: 232px;
  margin-top: 14px;
  margin-left: 20px;
}
.admin-dashboard-sidebar-items {
  margin-top: 65.89px;
  list-style: none;
  color: #fff;
  font-size: 14px;
  display: grid;
  grid-template-columns: 1fr;
  place-items: center;
  padding-bottom: 270px;
}
.admin-dashboard-sidebar-items li {
  width: 251px;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  margin-bottom: 10px;
}
.admin-dashboard-sidebar-items span {
  width: 14px;
  height: 14px;
  object-fit: cover;
  display: inline-block;
  margin-left: 22px;
  margin-right: 22px;
}
.admin-dashboard-sidebar-items .active {
  border-radius: 10px;
  background: #4c9de8;
}
.admin-dashboard-settings-logout .active {
  border-radius: 10px;
  background: #4c9de8;
}
.admin-dashboard-page {
  display: flex;
}
.admin-dashboard-settings-logout {
  margin-top: 21px;
  list-style: none;
  color: #fff;
  font-size: 14px;
  display: grid;
  grid-template-columns: 1fr;
  place-items: center;
  padding-bottom: 100px;
}
.admin-dashboard-settings-logout li {
  width: 251px;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  margin-bottom: 10px;
}
.admin-dashboard-settings-logout span {
  width: 14px;
  height: 14px;
  object-fit: cover;
  display: inline-block;
  margin-left: 22px;
  margin-right: 22px;
}
.admin-dashboard-mainpage {
  width: calc(100% - 294px);
  margin-left: 294px;
}
@media only screen and (max-width: 768px) {
  .admin-dashboard-mainpage {
    grid-template-rows: 0px 0px 1fr;
    width: 100vw;
    grid-template-columns: 100vw;
    margin-left: 0;
    /* margin-top: 60px; */
  }
}
.admin-dashboard-mainpage .component-area {
  background: rgba(135, 206, 250, 0.1);
  margin: 0;
  box-sizing: border-box;
  padding: 30px 20px 66px 20px;
}
@media only screen and (max-width: 768px) {
  .admin-dashboard-mainpage .component-area {
    padding: 15px;
    margin-top: 45px;
    background: #fff;
  }
}
.admin-dashboard-mainpage .header-area {
  padding-top: 31px;
  padding-bottom: 31px;
  padding-left: 20px;
  padding-right: 80px;
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 768px) {
  .admin-dashboard-mainpage .header-area {
    display: none;
  }
}
.admin-dashboard-mainpage .header-area h4 {
  font-family: Oxygen;
  font-weight: bold;
  font-size: 18px;
  line-height: 38px;
  color: #15294d;
}
.admin-dashboard-mainpage .header-area input {
  width: 217px;
  height: 34px;
  font-size: 10px;
  padding-left: 35px;
}

.advance-customer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
  display: grid;
  place-items: center;
}
.advance-customer-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: -1;
}
.advance-customer .content {
  padding: 25px;
  background: #fff;
  width: 494px;
  border-radius: 10px;
}
.advance-customer .content h4 {
  font-family: Asap;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  color: #15294d;
}
.advance-customer .content input {
  width: 100%;
  height: 40px;
  margin-bottom: 20px;
  border: 1px solid #adcae2;
  outline: none;
  border-radius: 10px;
}
.advance-customer .content select {
  width: 100%;
  height: 40px;
  margin-bottom: 20px;
  border: 1px solid #adcae2;
  outline: none;
  border-radius: 10px;
}
.advance-customer .content textarea {
  width: 100%;
  height: 100px;
  margin-bottom: 20px;
  border: 1px solid #adcae2;
  outline: none;
  border-radius: 10px;
  resize: none;
}
.advance-customer .content span {
  margin-left: 5px;
  color: #61606199;
}
.advance-customer .content button {
  padding: 7px 28px;
  color: white;
  background: #4c9de8;
  border-radius: 5px;
  font-size: 14px;
  border: none;
  /* margin-left: 78%; */
  float: right;
}
@media only screen and (max-width: 768px) {
  .advance-customer .content {
    width: 85vw;
  }
  .advance-customer .content button {
    /* margin-left: ; */
  }
}

.UserDetails {
  position: relative;
  width: 100%;
}
.userDetails-page-header div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-family: "Asap", sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #15294d;
}

.userDetails-page-header div p:first-child {
  font-size: 14px;
}

.userDetails-page-header > p {
  font-weight: bold;
}

.userDetails-page-header > p span {
  cursor: pointer;
  text-decoration: underline;
}
.credit-status-wrapper {
  display: flex;
  align-items: center;
  font-size: 18px;
}
.credit-status {
  height: 25px;
  width: 50px;
  border-radius: 5px;
  margin: 0px 0px 0px 10px;
}
.selected-user-details {
  position: absolute;
  display: flex;
  overflow-x: scroll;
  width: 100%;
  margin-bottom: 20px;
}
.selected-user-details::-webkit-scrollbar {
  height: 7px;
}
.selected-user-details::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 20px;
}
.user-detail-tile {
  background: #ffffff;
  border-radius: 10px;
  padding: 20px 30px;
  height: 350px;
  margin-right: 20px;
}
.user-details-description {
  font-family: "Asap", sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #15294d;
  margin-bottom: 20px;
}
.detail-description {
  font-family: "Asap", sans-serif;
  font-weight: 600;
  font-size: 15px;
  color: #15294d;
  width: 300px;
}
.detail-value {
  color: #616061;
  margin-left: 7px;
}
.user-details-navigation {
  margin-top: 400px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-around;
}
.user-details-navigation-item-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.user-details-navigation-item {
  font-family: "Asap", sans-serif;
  font-weight: 600;
  font-size: 17px;
  margin: 0;
  color: #61606199;
}
.navigation-active {
  color: #15294d;
}
.user-details-navigation-indicator {
  height: 5px;
  width: 100px;
  background: #4c9de8;
  border-radius: 10px;
}
.UserDetails .preview-body {
  border-radius: 0px 0px 10px 10px;
  border: none;
  background: #fff;
}
.UserDetails .selection-details-element {
  margin-right: 20px;
  width: 250px;
}
.UserDetails .recommended-details-tile {
  margin-right: 10px;
  width: 310px;
}
.rank-feedback {
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 20px;
}
.user-details-payment-graph {
  background: #fff;
  padding: 30px;
  margin-bottom: 20px;
}
.user-details-payment-details {
  position: relative;
  width: 100%;
  height: 500px;
  overflow-x: scroll;
  margin-bottom: 50px;
}
.user-details-payment-details::-webkit-scrollbar {
  height: 7px;
}
.user-details-payment-details::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 20px;
}
.payment-details-table-head {
  background: #adcae2;
  color: #15294d;
  font-weight: 600;
  font-family: "Asap", sans-serif;
  padding: 20px 50px;
}
.payment-details-table th,
.payment-details-table td {
  padding: 10px 40px 0px 30px;
  font-size: 14px;
  width: 60px !important;
}
.payment-details-sn-head {
  border-top-left-radius: 15px;
}
.payment-details-status-head {
  border-top-right-radius: 15px;
}
.payment-details-table td {
  color: #15294d;
  font-weight: 600;
  font-family: "Asap", sans-serif;
  background: #fff;
}
.confirmpayment-btn {
  color: white;
  font-size: 12px;
  background: rgb(76, 157, 232);
  outline: none;
  border: none;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
}

.resend-activation-mail {
  border-top: 1px solid #cccccc;
  padding-top: 20px;
}

.resend-activation-mail button {
  color: white;
  background: rgb(76, 157, 232);
  outline: none;
  border: none;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
}

.agent-info-item .agent-info-item-label {
  font-weight: bold;
}

.agent-info-item .identification-wrapper {
  border-radius: 8px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
  padding: 10px 20px;
  margin: 30px 0;
  max-width: 600px;
}

.agent-info-item .identification-wrapper-imgs {
  display: flex;
  flex-wrap: wrap;
}

.agent-info-item .identification-wrapper img {
  height: 100px;
  width: 200px;
  object-fit: contain;
  cursor: pointer;
}

.agent-fullimage-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.agent-fullimage-content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  padding: 25px;
  background: #fff;
  height: 600px;
  width: 800px;
  border-radius: 8px;
  box-sizing: border-box;
}

.agent-fullimage-content p {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  color: #15294d;
  font-weight: bold;
  background: rgba(255, 255, 255, 0.5);
  padding: 3px 8px;
  border-radius: 5px;
}

.agent-fullimage {
  height: 100%;
  width: 100%;
  object-fit: contain;
  cursor: pointer;
}

@media (max-width: 60rem) {
  .agent-fullimage-content {
    height: 80%;
    width: 80%;
  }
}

.ComponentPricing {
  background: #fff;
  border-radius: 10px;
  padding: 30px 40px;
  margin: 0;
  box-sizing: border-box;
  box-sizing: border-box;
}
.productlist-text {
  margin: 0;
  color: #15294d;
  font-weight: 600;
  font-family: "Asap", sans-serif;
  font-size: 22px;
}
.productlist-table {
  margin-top: 30px;
}
.ComponentPricing th {
  padding: 5px 30px 5px 5px;
}
.productlist-table-head {
  background: #adcae2;
  color: #15294d;
  font-weight: 600;
  font-family: "Asap", sans-serif;
}
.productlist-description-head {
  border-radius: 5px 0px 0px 5px;
  padding: 6px 0px 6px 30px !important;
}
.productlist-totalprice-head {
  border-radius: 0px 5px 5px 0px;
  padding: 6px 50px 6px 0px !important;
}
.ComponentPricing td {
  border: 2px solid #adcae2;
  border-top: none;
  border-collapse: collapse;
  padding: 5px 30px 5px 5px;
  color: #15294d;
  font-weight: 600;
  font-family: "Asap", sans-serif;
}
.productlist-description {
  border-left: none !important;
  padding-left: 30px !important;
}
.productlist-totalprice {
  border-right: none !important;
  padding-right: 30px !important;
}

@media (max-width: 40rem) {
  .ComponentPricing {
    padding: 0px;
  }
}

.mobile-nav {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background: #0d1071;
  z-index: 5;
}
@media only screen and (max-width: 768px) {
  .mobile-nav {
    display: block;
  }
}
.mobile-nav .small-logo {
  display: inline-block;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
}
.mobile-nav .flex {
  display: flex;
  /* justify-content: space-between; */
}
.mobile-nav .fabars {
  display: inline-block;
  align-self: center;
  margin-left: calc(100vw - 160px);
  cursor: pointer;
}

.analytics-visitors {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-evenly; */
}
.analytics-visitors .single {
  background: #fff;
  border-radius: 10px;
  padding: 8px 20px;
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-right: 25px;
  margin-bottom: 20px;
}
.analytics-visitors .single .title {
  color: #616061;
  font-weight: 500;
}
.barchart-container {
  padding: 10px 10px;
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.bar-chart {
  height: 250px;
  width: 400px;
  margin-top: 40px;
  margin-bottom: 80px;
}
.bar-chart .img-1 {
  position: absolute;
  left: 0px;
}
.bar-chart .img-2 {
  position: absolute;
  bottom: 0px;
  left: 69px;
}
.leads-prospects {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  /* color: rgba(97, 96, 97, 0.6); */
}
.leads-prospects span {
  cursor: pointer;
}
.leads-prospects .active {
  color: "#15294D";
  border-bottom: 4px solid #4c9de8;
}
.leads-prospects .disabled {
  color: rgba(97, 96, 97, 0.6);
}
.analytics-serach-wrapper {
  display: flex;
  align-items: center;
  background-color: #fff;
  width: 400px;
  margin-bottom: 20px;
  height: 40px;
  border-radius: 8px;
  padding: 0 10px;
}

.analytics-serach-wrapper input {
  margin-left: 10px;
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
}
.analytics .analytics-table-card {
  background: #fff;
  margin-bottom: 40px;
  border-radius: 10px;
  padding: 20px;
}
.analytics .analytics-table-wrapper {
  overflow-x: auto;
  font-size: 14px;
}
.analytics .analytics-table-wrapper::-webkit-scrollbar {
  height: 7px;
}
.analytics .analytics-table-wrapper::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 20px;
}
.analytics table tr {
  border-bottom: 1px solid #adcae2;
}
.analytics table {
  width: 100%;
}
.analytics table th {
  color: #616061;
  font-weight: 500;
  padding: 10px 20px;
}
.analytics table td {
  color: #15294d;
  font-weight: 600;
}
.analytics table tr td {
  padding: 10px 20px;
  box-sizing: border-box;
}

.analytics table tr td .noteIcon {
  width: 25px;
  cursor: pointer;
}

@media (max-width: 40rem) {
  .bar-chart {
    height: 250px;
    width: 380px;
    margin-top: 40px;
    margin-bottom: 80px;
  }
}

@media (max-width: 23rem) {
  .bar-chart {
    height: 250px;
    width: 280px;
    margin-top: 40px;
    margin-bottom: 80px;
  }
}

.agent-form-photograph {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.agent-form-id {
  height: 100px;
  width: 100%;
  border-radius: 8px;
  object-fit: contain;
}

