.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 90px;
  padding: 10px 70px 10px 0px;
  background-color: #0d1071;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
}
.logo {
  width: 300px;
  cursor: pointer;
}
.header-right-wrapper {
  display: flex;
  align-content: center;
}
.header-right-wrapper a {
  text-decoration: none;
}
.logout-btn {
  color: #fff;
  margin: 0;
  display: flex;
  align-items: center;
  margin-right: 50px;
  cursor: pointer;
}
.logout-btn:hover {
  color: #d0d0d0;
}
.header-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 45px;
  background: #4c9de8;
  border-radius: 5px;
  color: #ffffff;
  border: #4c9de8;
  font-family: "Asap", sans-serif;
  font-weight: 600;
  font-size: 17px;
}
.header-btn:hover {
  cursor: pointer;
  background-color: #4c5da8;
}

@media (max-width: 40rem) {
  .header {
    height: 60px;
    padding: 10px 20px 10px 0px;
  }
  .logo {
    width: 200px;
  }
  .header-btn {
    display: none;
  }
}
