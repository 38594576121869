.settings-form-input-wrapper{
    margin-top: 20px;
    width: 500px;
    position: relative;
}
.settings-form-input-label{
    margin: 0;
}
.settings-form-input-wrapper input{
    border-radius: 10px;
    width: 100%;
    height: 40px;
    outline: none;
    padding: 0 30px 0 10px;
}
.settings-form-input-tile{
    border: 1px solid #adcae2;
}
.settings-form-input-error{
    border: 1px solid #dc3545;
}
.settings-formfeedback{
    color: #dc3545;
}
.settings-form-input-eye{
    position: absolute;
    top: 30px;
    right: 10px;
    cursor: pointer;
}
.settings-form-submit-btn button{
    padding: 7px 40px;
    color: white;
    background: #4c9de8;
    border-radius: 5px;
    font-size: 14px;
    border: none;
    margin-top: 20px;
}

@media (max-width: 60rem) {
    .settings-form-input-wrapper{
        width: 100%;
    }
}