.LoginPage {
  padding-top: 90px;
}
.LoginPage .login-header {
  width: 80%;
}
.LoginPage .login-header-text {
  margin: 60px 0px 20px 0px;
  font-family: "Asap", sans-serif;
  font-weight: 600;
  font-size: 17px;
}
.LoginPage .form-container {
  width: 80%;
  font-family: "Asap", sans-serif;
}
.form-input-wrapper .container {
  height: 45px;
  width: 100%;
  position: relative;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.form-input-wrapper .container input {
  position: absolute;
}
.form-input-wrapper .container .eye {
  position: absolute;
  right: 0;
  cursor: pointer;
  font-size: 22px;
  padding: 5px;
}
.LoginPage .form-container .form-input-wrapper {
  margin-bottom: 20px;
}
.LoginPage .form-container .form-input-tile {
  height: 100%;
  width: 100%;
  background: #f7fafc;
  border: 2px solid #adcae2;
  border-radius: 5px;
  outline: none;
  padding-left: 10px;
  font-family: "Asap", sans-serif;
  font-weight: 500;
}
.LoginPage .form-container .form-input-error {
  height: 100%;
  width: 100%;
  background: #f7fafc;
  border: 1px solid #dc3545;
  border-radius: 5px;
  outline: none;
  padding-left: 10px;
}
.formfeedback {
  color: #dc3545;
  font-size: 14px;
}
.LoginPage .form-container .remember-forgot {
  display: flex;
  justify-content: space-between;
}
.LoginPage .form-container .remember-me {
  display: flex;
  align-items: center;
  color: #4c9de8;
}
.LoginPage .form-container .remember-me input {
  margin-right: 7px;
  background: #f7fafc;
  border: 1px solid #4c9de8;
  height: 20px;
  width: 20px;
}
.LoginPage .form-container .form-submit-btn {
  display: block;
}
.LoginPage .form-container .form-submit-btn button {
  width: 100%;
}
.form-gotologin {
  text-align: center;
  margin-top: 30px;
}

@media (max-width: 40rem) {
  .LoginPage {
    padding-top: 60px;
  }
  .LoginPage .form-container .form-submit-btn button {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .LoginPage .form-container .form-input-tile,
  .LoginPage .form-container .form-input-error {
    background: #fff;
  }
  .LoginPage .form-container .form-input-tile {
    border: 1px solid #adcae2;
  }
}
@media (max-width: 26rem) {
  .LoginPage .form-container {
    width: 90%;
  }
  .LoginPage .login-header {
    width: 90%;
  }
}
