.FirstPage,
.introPage {
  padding-top: 90px;
}
.grid1 {
  width: 50%;
  min-height: 100vh;
  background: #f7fafc;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 10px;
}
.fp-solution {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  margin-top: 30px;
}
.fp-custom-solution,
.fp-standard-solution {
  width: 260px;
  height: 160px;
  box-shadow: 0px 4px 8px rgba(23, 43, 79, 0.2);
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px 2px 0px 2px;
}
.Design-Custom-Solution,
.Standard-Solution {
  text-align: center;
  font-family: "Asap", sans-serif;
  font-weight: 700;
  font-size: 18px;
  margin: 0 0 30px 0;
  color: #15294d;
}
.fp-select {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 40px;
  background: #4c9de8;
  border-radius: 5px;
  color: #ffffff;
  border: #4c9de8;
  font-family: "Asap", sans-serif;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
}
.fp-select:hover {
  color: #fff;
  background-color: #0d1071;
  border: 2px solid #0d1071;
}
.get-started-video {
  width: 100%;
  margin-top: 100px;
  text-align: center;
}
.get-started-video iframe {
  width: 90%;
}
.grid2 {
  position: fixed;
  top: 90px;
  bottom: 0;
  right: 0;
  width: 50%;
  background-color: rgba(21, 41, 77, 0.3);
  background-image: url("../../images/Rectangle 53.png");
  background-blend-mode: overlay;
  background-size: cover;
  background-repeat: no-repeat;
}
.fp-reliable {
  margin: 120px 0 0 80px;
}
.fp-energy {
  font-family: "Oxygen", sans-serif;
  font-weight: bold;
  font-size: 55px;
  color: #ffffff;
}
.intro-get-started {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: #4c9de8;
  border-radius: 5px;
  color: #ffffff;
  border: #4c9de8;
  font-family: "Asap", sans-serif;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  padding: 10px 30px;
  display: none;
}
.intro-get-started:hover {
  color: #fff;
  background-color: #0d1071;
  border: 2px solid #0d1071;
}

@media (max-width: 60rem) {
  .grid1 {
    width: 100%;
  }
  .grid2 {
    display: none;
  }
  .introPage .grid1 {
    display: none;
  }
  .introPage .grid2 {
    display: block;
    width: 100%;
  }
  .intro-get-started {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 40rem) {
  .FirstPage,
  .introPage {
    padding-top: 60px;
  }
  .grid1 {
    background: #fff;
    padding: 30px 10px;
  }
  .introPage .grid2 {
    top: 60px;
  }
  .fp-custom-solution,
  .fp-standard-solution {
    margin: 10px 2px 20px 2px;
  }
  .fp-reliable {
    margin: 120px 0 0 30px;
    padding-right: 10px;
  }
  .fp-energy {
    font-size: 33px;
  }
}
