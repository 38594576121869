.agent-form-photograph {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.agent-form-id {
  height: 100px;
  width: 100%;
  border-radius: 8px;
  object-fit: contain;
}
