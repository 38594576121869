.LandingDashboard {
  padding: 120px 60px 60px 60px;
  background: #f7fafc;
  min-height: 100vh;
}

.preview-header {
  background: #adcae2;
  border-radius: 10px 10px 0px 0px;
  padding: 5px 15px;
}
.preview-header-text {
  margin: 0;
  color: #0d1071;
  font-weight: 600;
  font-family: "Asap", sans-serif;
}

.LandingDashboard .preview-body {
  border-radius: 0px 0px 10px 10px;
  border: none;
  background: #fff;
}

.LandingDashboard .preview-selection-details {
  margin-bottom: 30px;
}

.LandingDashboard .preview-dashboard .preview-body {
  border-radius: 10px;
  border: none;
  padding: 15px 50px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.landing-dashboard-user-personal-wrapper {
  display: flex;
  flex-direction: column;
}
.run-credit-check {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.run-credit-check a {
  margin-bottom: 25px;
}
.run-credit-check a,
.run-credit-check button {
  padding: 10px 20px;
  font-size: 18px;
  display: flex;
  align-items: center;
  background: #4c9de8;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  border: 2px solid #4c9de8;
}
.run-credit-check a:hover,
.run-credit-check button:hover {
  background: #fff;
  color: #4c9de8;
}
.landing-dashboard-user-personal {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
}

.landing-dashboard-user-img {
  position: relative;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background: #adcae2;
  margin-right: 30px;
  margin-bottom: 20px;
}

.landing-dashboard-user-img-icon {
  position: absolute;
  color: transparent;
  width: 30px;
  bottom: 0;
  right: 0;
}

.landing-dashboard-user-img-icon::-webkit-file-upload-button {
  visibility: hidden;
}
.landing-dashboard-user-img-icon::before {
  content: "S";
  display: inline-block;
  background: url("../../images/imgIcon.png");
  background-size: cover;
  border: 1px solid #999;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-size: 5px;
}
.landing-dashboard-user-img-icon:hover::before {
  border-color: black;
}

.landing-dashboard-user-name {
  color: #15294d;
  font-family: "Oxygen", sans-serif;
  font-weight: 600;
  font-size: 30px;
}

.landing-dashboard-user-email {
  color: #616061;
  font-family: "Asap", sans-serif;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 0;
}

.landing-dashboard-contact-info {
  color: #15294d;
  font-family: "Oxygen", sans-serif;
  font-weight: 700;
}

.landing-dashboard-installation-address {
  margin: 20px 0px 10px 0px;
}

.landing-dashboard-installation-address,
.landing-dashboard-phone {
  color: #616061;
  font-family: "Asap", sans-serif;
  font-weight: 500;
}

.landing-dashboard-installation-address .bold,
.landing-dashboard-phone .bold {
  color: #15294d;
  font-weight: 700;
}

@media (max-width: 40rem) {
  .LandingDashboard {
    padding: 80px 20px 60px 20px;
    background: #fff;
  }
  .LandingDashboard .preview-body {
    border-radius: 0px 0px 10px 10px;
    border: 1px solid #adcae2;
  }
  .LandingDashboard .preview-dashboard .preview-body {
    padding: 5px 0px;
  }
  .run-credit-check {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  .run-credit-check a,
  .run-credit-check button {
    padding: 5px 10px;
    font-size: 16px;
    margin: 25px 0px;
  }
  .landing-dashboard-user-img {
    margin-right: 20px;
  }
  .landing-dashboard-user-name {
    font-size: 18px;
  }
  .landing-dashboard-user-email {
    font-size: 15px;
  }
}

@media (max-width: 23rem) {
  .run-credit-check a,
  .run-credit-check button {
    font-size: 14px;
  }
}
