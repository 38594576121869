.analytics-visitors {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-evenly; */
}
.analytics-visitors .single {
  background: #fff;
  border-radius: 10px;
  padding: 8px 20px;
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-right: 25px;
  margin-bottom: 20px;
}
.analytics-visitors .single .title {
  color: #616061;
  font-weight: 500;
}
.barchart-container {
  padding: 10px 10px;
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.bar-chart {
  height: 250px;
  width: 400px;
  margin-top: 40px;
  margin-bottom: 80px;
}
.bar-chart .img-1 {
  position: absolute;
  left: 0px;
}
.bar-chart .img-2 {
  position: absolute;
  bottom: 0px;
  left: 69px;
}
.leads-prospects {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  /* color: rgba(97, 96, 97, 0.6); */
}
.leads-prospects span {
  cursor: pointer;
}
.leads-prospects .active {
  color: "#15294D";
  border-bottom: 4px solid #4c9de8;
}
.leads-prospects .disabled {
  color: rgba(97, 96, 97, 0.6);
}
.analytics-serach-wrapper {
  display: flex;
  align-items: center;
  background-color: #fff;
  width: 400px;
  margin-bottom: 20px;
  height: 40px;
  border-radius: 8px;
  padding: 0 10px;
}

.analytics-serach-wrapper input {
  margin-left: 10px;
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
}
.analytics .analytics-table-card {
  background: #fff;
  margin-bottom: 40px;
  border-radius: 10px;
  padding: 20px;
}
.analytics .analytics-table-wrapper {
  overflow-x: auto;
  font-size: 14px;
}
.analytics .analytics-table-wrapper::-webkit-scrollbar {
  height: 7px;
}
.analytics .analytics-table-wrapper::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 20px;
}
.analytics table tr {
  border-bottom: 1px solid #adcae2;
}
.analytics table {
  width: 100%;
}
.analytics table th {
  color: #616061;
  font-weight: 500;
  padding: 10px 20px;
}
.analytics table td {
  color: #15294d;
  font-weight: 600;
}
.analytics table tr td {
  padding: 10px 20px;
  box-sizing: border-box;
}

.analytics table tr td .noteIcon {
  width: 25px;
  cursor: pointer;
}

@media (max-width: 40rem) {
  .bar-chart {
    height: 250px;
    width: 380px;
    margin-top: 40px;
    margin-bottom: 80px;
  }
}

@media (max-width: 23rem) {
  .bar-chart {
    height: 250px;
    width: 280px;
    margin-top: 40px;
    margin-bottom: 80px;
  }
}
