.feedback-modal-wrapper{
  z-index: 1;
}

.feedback-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 70vh;
  width: 40%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 10px;
  padding: 30px;
  overflow-y: scroll;
  overflow-x: hidden;
}

@media (max-width: 70rem) {
  .feedback-modal {
    width: 50%;
    padding: 20px;
  }
}

@media (max-width: 60rem) {
  .feedback-modal {
    width: 60%;
    padding: 20px;
  }
}

@media (max-width: 50rem) {
  .feedback-modal {
    width: 70%;
    padding: 20px;
  }
}

@media (max-width: 40rem) {
  .feedback-modal {
    width: 95%;
    padding: 20px;
  }
}