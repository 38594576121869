.wallet-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.wallet-details {
  background-color: #0d1071;
  color: white;
  border-radius: 8px;
  padding: 30px 20px;
  margin-bottom: 20px;
  width: 400px;
  box-sizing: border-box;
}

.wallet-details p {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wallet-details button {
  border: none;
  outline: none;
  border-radius: 8px;
  background-color: white;
  color: #0d1071;
  height: 40px;
  padding: 0 30px;
  font-size: 14px;
  margin-top: 20px;
}

.wallet-details button img {
  width: 20px;
  margin-right: 10px;
}

.wallet-details .referrals-link-copied {
  color: white;
  margin-left: 60px;
}

.wallet-input-wrapper {
  background-color: white;
  border-radius: 8px;
  box-shadow: -1px 1px 15px #c7c6c6;
  padding: 30px 20px;
  width: 400px;
  margin-bottom: 20px;
  color: #0d1071;
}

.wallet-input-wrapper input {
  width: 100%;
  height: 40px;
  margin-bottom: 20px;
  border: 1px solid #adcae2;
  outline: none;
  border-radius: 10px;
  padding: 0 10px;
}

.wallet-input-wrapper button {
  border: none;
  outline: none;
  border-radius: 8px;
  background-color: #0d1071;
  color: white;
  height: 40px;
  padding: 0 30px;
  font-size: 14px;
  margin-top: 20px;
}

.wallet-input-wrapper .asset-name {
  margin: 0;
  font-weight: bold;
}

.wallet-input-wrapper .asset-unit-name {
  margin: 0;
  font-size: 14px;
}

.wallet-input-wrapper .asset-amount {
  margin: 0;
}

@media (max-width: 60rem) {
  .wallet-details {
    width: 100%;
  }

  .wallet-details p {
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .wallet-input-wrapper {
    width: 100%;
  }
}
